<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>

					<p>
						Read through the readings below, then proceed down to
						the bottom of the page and answer the questions to
						complete this section.
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#reading"> Reading</router-link>
							<ul>
								<li
									v-for="(item, index) in reading"
									:key="index"
								>
									<router-link :to="'#reading' + index"
										>{{ item.head }}
									</router-link>
								</li>
							</ul>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.expectations.applicable ==
												true &&
											data.expectations.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#one"
										>Expectations</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.training.applicable == true &&
											data.training.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#two"
										>Training</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.communication.applicable ==
												true &&
											data.communication.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#three"
										>Communication</router-link
									>
								</li>

								<li>
									<span
										v-if="
											data.verify.applicable == true &&
											data.verify.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#four">Verify</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="step1">
						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-3"
							name="step2"
							class="large"
						></Buttons>
						<hr />
						<h2 id="reading">Reading</h2>
						<div class="accordion">
							<div
								v-for="(item, index) in reading"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2
									class="accordion-header"
									:id="'reading' + index"
								>
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<h2 id="questions">Questions</h2>
						<p>
							Below are four questions on how you set and agree
							expectations about biosecurity requirements on-site.
							Read the sample actions and considerations which may
							help to guide you on how you would approach this,
							and then enter your plan in the text boxes below.
						</p>

						<Question
							boxTitle="Agree what must happen on site"
							idTitle="one"
							subTitle="Set expectations with post-harvest, contractors and
							managers"
							radioAcceptance="Who are the post-harvest operators,
										contractors and orchard managers I have
										established my biosecurity expectations
										with?"
							:datas="data.expectations"
						>
							<p>
								Set your expectations with post-harvest,
								contractors and managers. They play a key role
								in biosecurity risk management. You may wish to
								formalise expectations in their contracts.
							</p>
							<p>
								Zespri CAV contractors have and operate to a
								biosecurity plan as part of their accreditation.
								For those outside the Zespri CAV scheme a
								template to complete is available at
								www.kvh.org.nz – check this has been done.
							</p>
						</Question>

						<Question
							boxTitle="Agree what must happen on site"
							idTitle="two"
							subTitle="Train your people so they understand the risk, your
							expectations and stay engaged"
							radioAcceptance="What is my training plan to ensure staff
										understand our biosecurity expectations?"
							:datas="data.training"
						>
							<p>
								Train your staff so they can achieve the agreed
								biosecurity expectations. Remember to use
								language they understand.
							</p>
							<p>Training opportunities may include:</p>
							<ul class="read">
								<li>induction training</li>
								<li>refresher training</li>
								<li>
									updates, when change in risk requires it
								</li>
							</ul>
							<p>
								Understanding risk, and how practices reduce
								risk, is helpful in achieving uptake.
							</p>
						</Question>

						<Question
							boxTitle="Agree what must happen on site"
							idTitle="three"
							subTitle="Communicate your requirements to visitors"
							radioAcceptance="How I communicate biosecurity
										requirements to visitors:"
							:datas="data.communication"
						>
							<p>
								Make visitors aware of your biosecurity
								requirements to prevent them unintentionally
								introducing biosecurity threats to your orchard.
								This could be achieved with:
							</p>
							<ul class="read">
								<li>
									signage that has clear instructions and
									contact details
								</li>
								<li>
									a visitor register explaining requirements
									and instructions
								</li>
								<li>consideration of language difficulties</li>
							</ul>
						</Question>

						<Question
							boxTitle="Agree what must happen on site"
							idTitle="four"
							subTitle="Check your expectations and requirements are being
							met"
							radioAcceptance="How I check that my biosecurity
										expectations are being met:"
							:datas="data.verify"
						>
							<p>
								Verify that expectations are being met and risk
								is being managed. If not, review:
							</p>
							<ul class="read">
								<li>
									expectations with post-harvest, contractors
									and managers
								</li>
								<li>training</li>
								<li>communication</li>
							</ul>
						</Question>

						<br />
						<br />

						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="step-3"
							name="step2"
						></Buttons>

						<hr />
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, ref, computed, onMounted } from "vue";

	import Layout from "./planContainer.vue";

	import Buttons from "../../components/Buttons.vue";
	import PageNav from "../../components/PageNav.vue";

	import Question from "../../components/Question.vue";

	export default {
		setup() {
			const store = useStore();

			// const status = computed(() => store.state.create.step1.status);

			const status = computed(() => store.state.create.step2.status);

			const datas = computed(() => store.state.create.step2);
			const data = computed(() => store.state.create.step2.data);
			const reading = computed(() => store.state.create.step2.reading);
			const plan = computed(() => store.state.create.plan);

			const isComplete = computed(
				() => store.getters["create/step2/isComplete"]
			);

			const state = reactive({
				name: "STEP 2: AGREE WHAT MUST HAPPEN ON SITE",
			});

			onMounted(() => {
				store.dispatch("create/getsPlan", "whatAndWhy");
			});

			return {
				state,
				status,
				reading,
				datas,
				isComplete,
				data,
				plan,
			};
		},
		components: {
			Buttons,
			PageNav,
			Question,
			Layout,
		},
	};
</script>

<style lang="scss">
	.form-div {
		margin-bottom: 3rem;
		ul > li {
			list-style: none;
			padding-left: 1rem;
			margin-bottom: 0.75rem;
		}
	}
</style>
