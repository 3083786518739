<template>
	<textarea
		v-model="value"
		ref="textarea"
		class="form-control"
		:id="id"
		:placeholder="placeHolder"
		rows="3"
		@focus="resize"
		@keyup="resize"
		:disabled="!applicable"
		required
	>
	</textarea>
</template>

<script>
	export default {
		props: ["value", "id", "placeHolder", "required", "applicable"],
		mounted() {
			this.resize();
		},
		methods: {
			resize() {
				const { textarea } = this.$refs;
				textarea.style.minHeight = "80px";
				textarea.style.height = textarea.scrollHeight + "px";
			},
		},
	};
</script>