<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>

					<p>
						Read through the readings below, then proceed down to
						the bottom of the page and answer the questions to
						complete this section.
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#reading"> Reading</router-link>
							<ul>
								<li
									v-for="(item, index) in reading"
									:key="index"
								>
									<router-link :to="'#reading' + index"
										>{{ item.head }}
									</router-link>
								</li>
							</ul>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.identification.applicable ==
											false
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#one"
										>Identification, assessment and
										response</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.effect.applicable == true &&
											data.effect.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#report"
										>Effect on OGR</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.talkTo.applicable == true &&
											data.talkTo.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#three"
										>Who to talk to</router-link
									>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="step1">
						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="overview"
							name="lastStep"
							class="large"
						></Buttons>
						<hr />
						<h2 id="reading">Reading</h2>
						<div class="accordion">
							<div
								v-for="(item, index) in reading"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2
									class="accordion-header"
									:id="'reading' + index"
								>
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<h2 id="questions">Questions</h2>
						<p>
							Read the information below and note how you can help
							ensure smooth-running after a report is made.
						</p>
						<div class="block"></div>

						<div>
							<div class="form-title" id="one">
								<h3>After reporting</h3>
								<span
									>Identification, assessment and
									response</span
								>
							</div>
							<div class="form-div">
								<div class="row">
									<p>
										The suspicious find is identified. In
										most instances it is found to not be of
										concern and no further action is
										required.
									</p>
									<p>
										If further action is required, the
										pest/pathogen is assessed to determine
										if a response is needed. Biosecurity New
										Zealand will contain the pest/pathogen
										to understand more about it and the
										impact it may have.
									</p>
									<p>
										Biosecurity New Zealand, KVH and any
										other affected groups then work together
										to decide whether to formally respond
										and if so, set goals such as eradication
										or containment. Sometimes a response
										then moves into long-term management (as
										is the case with Psa currently).
									</p>
									<h3>
										Actions I can take to ensure
										smooth-running
									</h3>
									<p>
										Timeframes from notification to a
										response decision vary. For pests with
										serious impacts that we know a lot about
										(like fruit fly) it can be immediate,
										but for others it may take weeks.
									</p>
									<p>After reporting I:</p>
									<ul>
										<li>
											access and provide records and
											information when requested
											(traceability information of plant
											material movements on and off the
											property is critical for a
											successful response)
										</li>
										<li>
											follow directions to manage the
											pest/pathogen
										</li>
										<li>
											respect confidentiality to avoid
											unnecessary market reaction.
										</li>
									</ul>
								</div>

								<div class="row">
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											id="identification"
											v-model="
												data.identification.applicable
											"
										/>
										<label
											class="form-check-label"
											for="identification"
										>
											I have read and understand the above
											text
										</label>
									</div>
								</div>
								<br />
							</div>
						</div>
						<Question
							boxTitle="After reporting"
							subTitle="Effect on OGR"
							idTitle="two"
							radioAcceptance="Where my records can be found:"
							:datas="data.effect"
						>
							<p>
								Most reports of unusual symptoms turn out to not
								be a biosecurity threat and there are no
								implications for growers. However, if a response
								is activated and losses are incurred because of
								response activities, you will be eligible for
								compensation under the Biosecurity Act (there
								are conditions).
							</p>

							<p>
								Losses must be verifiable, so good production
								and business records are essential for
								compensation claims.
							</p>
						</Question>

						<Question
							boxTitle="After reporting"
							subTitle="Who to talk to"
							idTitle="three"
							radioAcceptance="Where I store phone numbers/contact details for KVH, NZKGI and my post-harvest operator:"
							:datas="data.talkTo"
						>
							<p>
								KVH provides regular information advice about
								managing identified pests/pathogens and how a
								response is unfolding.
							</p>
							<p>
								NZKGI provides advice and support information to
								growers.
							</p>

							<p>
								Post-harvest operators help with operations and
								advice.
							</p>
						</Question>

						<br />
						<br />

						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="overview"
							name="lastStep"
						></Buttons>

						<hr />
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, computed, onMounted } from "vue";
	import Layout from "./planContainer.vue";

	import Buttons from "../../components/Buttons.vue";
	import PageNav from "../../components/PageNav.vue";
	import Question from "../../components/Question.vue";

	export default {
		setup() {
			const store = useStore();

			// const status = computed(() => store.state.create.step1.status);

			const status = computed(() => store.state.create.lastStep.status);

			const datas = computed(() => store.state.create.lastStep);
			const data = computed(() => store.state.create.lastStep.data);
			const reading = computed(() => store.state.create.lastStep.reading);
			const plan = computed(() => store.state.create.plan);

			const isComplete = computed(
				() => store.getters["create/lastStep/isComplete"]
			);

			const state = reactive({
				name: "WHAT HAPPENS AFTER I MAKE A REPORT?",
			});

			onMounted(() => {
				store.dispatch("create/getsPlan", "lastStep");
			});
			return {
				state,
				status,
				reading,
				datas,
				isComplete,
				data,
				plan,
			};
		},
		components: {
			Buttons,
			PageNav,
			Question,
			Layout,
		},
	};
</script>

<style lang="scss">
	.form-div {
		margin-bottom: 3rem;
		ul > li {
			list-style: none;
			padding-left: 1rem;
			margin-bottom: 0.75rem;
		}
	}
</style>
