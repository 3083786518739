<template lang="">
  <ul class="navbar-nav" ref="randomIs">
    <div v-if="datas != 'plan'">
      <li v-for="(menu, index) in state.menus" class="nav-item">
        <router-link class="nav-link" :to="menu.link">{{ menu.title }}</router-link>
      </li>
    </div>
    <div v-else>
      <ul class="sub-menu">
        <div ref="subMenus">
          <li class="nav-item">
            <router-link class="nav-link" to="/dashboard">
              <i class="fa fa-arrow-left"></i> Back to dashboard</router-link
            >
          </li>
          <li class="nav-item"><h2>Create Plan</h2></li>
          <div class="list">
            <li v-for="(menu, index) in state.menus[2].children" class="nav-item">
              <router-link :class="{ disabled: isNew }" class="nav-link" :to="'/plan/'+id+'/'+menu.link"
                ><span class="index">{{ index - 1 }}.</span> {{ menu.title }}</router-link
              >
            </li>
          </div>
        </div>
      </ul>
    </div>
    <slot></slot>
  </ul>
</template>
<script>
	import { computed, onMounted, onUpdated, reactive, ref } from "vue";
	import { useRoute, onBeforeRouteLeave } from "vue-router";
	import { useStore } from "vuex";
	export default {
		setup() {
			const store = useStore();
			const subMenus = ref();
			const randomIs = ref();
			const route = useRoute();

			const id = computed(() => store.getters["create/getID"]);
			const isSubmenu = computed(() => store.state.isSubmenu);
			const isNew = computed(() => store.state.create.new);

			const state = reactive({
				menus: [
					{
						title: "Dashboard",
						link: "/dashboard",
					},
					{
						title: "my plans",
						link: "/plans",
					},
					{
						title: "create a plan",
						link: "/plan",

						children: [
							{
								title: "Overview",
								link: "overview",
							},
							{
								title: "Name & Info",
								link: "info",
							},
							{
								title: "What & Why",
								link: "what-and-why",
							},
							{
								title: "Step 1",
								link: "step-1",
							},
							{
								title: "Step 2",
								link: "step-2",
							},
							{
								title: "Step 3",
								link: "step-3",
							},
							{
								title: "Step 4",
								link: "step-4",
							},
							{
								title: "Step 5",
								link: "step-5",
							},
							{
								title: "What happens after I make a report?",
								link: "last-step",
							},
						],
					},
					{
						title: "search plans",
						link: "/search",
					},
					{
						title: "after I make a report",
						link: "/after",
					},
				],
			});
			const showSubmenu = () => {
				store.dispatch("showSubmenu", isSubmenu);
				randomIs.value.clientHeight(440);
			};

			// onBeforeRouteLeave((to, from) => {
			// 	if (to.params.id == from.params.id) {
			// 		const id = computed(() => route.params.id);
			// 	}
			// });

			onMounted(() => {
				// store.dispatch("create/getID");
			});

			return {
				isSubmenu,
				showSubmenu,
				state,
				isNew,
				subMenus,
				id,
			};
		},
		props: ["datas"],
	};
</script>
<style lang=""></style>
