<template>
	<Layout>
		<div class="page no-page-nav">
			<div class="row">
				<div class="col-lg-6">
					<div class="page-title">
						<h1>{{ name }}</h1>
						<p>
							View all of your plans below, or use the ‘Search
							plans’ page for more detailed searches.
						</p>
						<p>
							You can <span>‘edit’</span> and
							<span>‘delete’</span> plans that have not been
							submitted. You can <span>‘duplicate’</span> plans,
							which creates a brand new plan with all of your
							answers from Sections 2-7, you will just need to
							fill out sections 0 and 1.
						</p>
						<p>
							You can view the PDF’s created for each KPIN on the
							plan after a plan is submitted by clicking
							<span>‘View KPINs’</span>
						</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col">
					<new-plan-btn></new-plan-btn>
				</div>
				<div class="col search-bar">
					<div class="form-floating mb-3">
						<input
							type="text"
							class="form-control"
							id="floatingInput"
							placeholder="name@example.com"
							v-model="state.search"
						/>
						<label for="floatingInput">Search</label>
					</div>
				</div>
			</div>

			<Loading v-if="state.response.isLoading"> </Loading>

			<PlansList v-else :plans="searchedPlans">
				<Error
					v-if="state.response.status == 'error'"
					:message="state.response.message"
				>
				</Error>
				<div v-else-if="state.response.noPlans">
					<h4>You currently have no plans. Start a new plan</h4>
				</div>
			</PlansList>
		</div>
	</Layout>
</template>

<script>
	import Layout from "./mainContainer.vue";
	import { useStore } from "vuex";
	import { computed, ref, onMounted } from "vue";

	import PlansList from "../components/PlansList.vue";
	import Loading from "../components/Loading.vue";
	import Error from "../components/Error.vue";
	import Nav from "../components/Nav.vue";
	import NewPlanBtn from "../components/NewPlanBtn.vue";

	export default {
		setup() {
			const name = ref("My Plans");
			const store = useStore();

			const state = computed(() => store.state.plans);
			const searchedPlans = computed(
				() => store.getters["plans/searchedPlans"]
			);
			onMounted(() => {
				store.dispatch("plans/getPlans");
			});
			return {
				name,
				// state,
				state,
				searchedPlans,
			};
		},
		components: {
			PlansList,
			Loading,
			Error,
			Layout,
			Nav,
			NewPlanBtn,
		},
	};
</script>

<style lang="scss">
</style>
