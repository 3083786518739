<template lang="">
<div class="plan-table table">
	<slot></slot>
	<div class="row table-head">
		<b class="col">Name</b>
		<b class="col">Login</b>
		<b class="col">Date submitted</b>
		<b class="col">Status</b>
		<b class="col"></b>
	</div>
	{{sortedPlans}}
	<hr>
	<div class="card" v-for="(item, index) in searchedPlans" :key="item.id">
		<div class="row">
			<div class="col">
				<h3>{{ item.fullName }}</h3>
			</div>
			<div class="col">{{ item.user ? item.user.email : "" }}</div>
			<div class="col">{{ $filters.formatedDate(item.submittedAt) }}</div>
			<div class="col">
				<span class=text-green v-if="item.planStatus === 1">Submitted</span>
				<span v-else>Draft</span>
			</div>
			<div class="col ">
				<button class="btn yellow " data-bs-toggle="modal" :data-bs-target="'#plan-'+item.planId">
            View KPINs <i class="far fa-eye "></i>
          </button>
				<Kpins :datas="item "></Kpins>
			</div>
		</div>
	</div>
</div>
</template>
<script>
	import { useStore } from "vuex";
	import moment from "moment";
	import { computed, ref, onMounted } from "vue";
	import Kpins from "./ViewKpins.vue";

	export default {
		setup() {
			const store = useStore();

			const viewKpins = (id) => {};

			const userId = computed(() => store.state.plans.user);
			const searchedPlans = computed(
				() => store.getters["plans/searchedPlans"]
			);
			const sortedPlans = computed(() => store.getters["plans/sortedPlans"]);
			const submitted = new Date();

			onMounted(() => {
				// store.dispatch("plans/getPlans");
			});
			return { viewKpins, sortedPlans, searchedPlans, userId };
		},
		// props: ["plans"],
		components: {
			Kpins,
		},
	};
</script>
<style lang="scss">
	@import "../assets/scss/mixins.scss";
	table {
		border-collapse: separate !important;
		border-spacing: 0 0.75rem;
	}

	tbody {
		vertical-align: baseline !important;
		tr {
			border-color: transparent;
			box-shadow: 0 3px 6px $shadow;
			padding: 0.75rem 0;
			width: 100%;
			border-radius: 7px;
			overflow: hidden;
			td {
				border-radius: 5px;
				overflow: hidden;
				padding: 0 !important;
				padding-left: 8px !important;
				&:last-child {
					display: flex;
					justify-content: flex-end;
				}
				.btn.yellow {
					border-bottom: 1px solid $yellow;
					i {
						margin-left: 0.75rem;
					}
				}
			}
		}
	}
</style>
