<template lang="">
<div class="row plans-list">
	<slot></slot>
	<div class="col-xs-12" v-for="(item, index) in plans" :key="item.id">
		
		<div class="card row" data-tor="hover:push.up(xs)">
			<div class="col-xl-9">
				<div class="row">
					<div class="col-sm-5">
						<p class="d-none d-lg-block">Name</p>
						<h2>{{ item.fullName }}</h2>
					</div>
					<div class="col-md-4 d-none d-md-block" v-if="item.planStatus === 1">
						<p>Date submitted</p>
						<!-- {{ item.submittedAt  }} -->
						<p>{{ $filters.formatedDate(item.submittedAt) }}</p>
					</div>
					<div class="col-md-4 d-none d-md-block" v-else>
						<p>Date updated</p>
						<!-- {{ item.updatedAt }} -->
						<p>{{ $filters.formatedDate(item.createdAt) }}</p>
					</div>
					<div class="col-md-3 mb-hide">
						<p class="d-none d-lg-block">Status</p>
				<span class=text-green v-if="item.planStatus === 1">Submitted</span>
				<span v-else>Draft</span>
					</div>
				</div>
				<p class="text-clamp">
					{{ item.reminder }}
				</p>
			</div>
			<div class="col-xl-3">

				
				
				<div v-if="item.planStatus === 0" class="btns">
					<a data-bs-toggle="modal" :data-bs-target="'#edit'+index" class="btn green"><span>edit</span> <i class="far fa-edit"></i></a>
					<a data-bs-toggle="modal" :data-bs-target="'#duplicate'+index" class="btn green"><span>duplicate</span> <i class="far fa-clone"></i></a>
					<a data-bs-toggle="modal" :data-bs-target="'#delete'+index" class="btn green"><span>delete</span> <i class="far fa-trash-alt"></i></a>	
				</div>
				<div v-else class="btns">
					<a data-bs-toggle="modal" :data-bs-target="'#plan-'+item.planId" class="btn green"><span>View</span> <i class="far fa-eye "></i></a>
					<a data-bs-toggle="modal" :data-bs-target="'#duplicate'+index" class="btn green"><span>duplicate</span> <i class="far fa-clone"></i></a>
				</div>
				<!-- <Options :id="item.planId" :datas="item"> </Options> -->
			</div>

		</div>
		
				<Kpins :datas="item "></Kpins>
		
			<div
				class="modal fade"
				:id="'edit'+index"
				tabindex="-1"
				aria-labelledby="editLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="editLabel">
								<i class="far fa-edit"></i> Continue editing the answers to this plan?

							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn yellow"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							
							<router-link :to="'/plan/'+item.planId+'/overview'" class="btn">Yes </router-link>
							
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal fade"
				:id="'duplicate'+index"
				tabindex="-1"
				aria-labelledby="duplicateLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="duplicateLabel">
								 <i class="far fa-clone"></i> Are you sure you want to duplicate this plan?

							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							This will create a new plan that includes all of the answers entered for Sections 2 - 7. You will need to reenter the Name & Info section, and the What & Why section.
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn yellow"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							
							<a class="btn green" data-bs-dismiss="modal" @click="copyPlan(item.planId)">Duplicate</a>
							
						</div>
					</div>
				</div>
			</div>
			<div
				class="modal fade"
				
				:id="'delete'+index"
				tabindex="-1"
				aria-labelledby="deleteLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="deleteLabel">
								 <i class="far fa-trash-alt"></i> Are you sure you want to delete this plan? 

							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							You will not be able to undo this action.
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn yellow"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<a class="btn green" data-bs-dismiss="modal" @click="deletePlan(item.planId)">delete </a>
						</div>
					</div>
				</div>
			</div>
	</div>
</div>
</template>
<script>
	import { ref, computed } from "vue";
	import moment from "moment";
	import { useStore } from "vuex";
	import Options from "./Options.vue";
	import Kpins from "./ViewKpins.vue";

	export default {
		created: function () {
			this.moment = moment;
		},
		setup(props) {
			const store = useStore();
			const editPlan = (id) => {
				// store.dispatch("plans/edit", id);
				store.dispatch("plans/editPlan", id);
			};
			const copyPlan = (data) => {
				store.dispatch("create/duplicatePlan", data);
			};
			const deletePlan = (id) => {
				store.dispatch("plans/deletePlan", id);
			};
			const submitted = new Date();
			const updated = new Date();

			return {
				editPlan,
				copyPlan,
				deletePlan,
				submitted,
				updated,
			};
		},
		props: ["plans"],
		components: {
			Options,
			Kpins,
		},
	};
</script>
<style lang="scss">
</style>
