<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>

					<p>
						Read through the readings below, then proceed down to
						the bottom of the page and answer the questions to
						complete this section.
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#reading"> Reading</router-link>
							<ul>
								<li
									v-for="(item, index) in reading"
									:key="index"
								>
									<router-link :to="'#reading' + index"
										>{{ item.head }}
									</router-link>
								</li>
							</ul>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.rootstock.applicable == true &&
											data.rootstock.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#one"
										>New rootstock and budwood</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.pollen.applicable == true &&
											data.pollen.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#two">pollen</router-link>
								</li>
								<li>
									<span
										v-if="
											data.fertilizers.applicable ==
												true &&
											data.fertilizers.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#three"
										>Compost and organic
										fertilisers</router-link
									>
								</li>

								<li>
									<span
										v-if="
											data.other.applicable == true &&
											data.other.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#four"
										>Other plant material</router-link
									>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="step1">
						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-4"
							name="step3"
							class="large"
						></Buttons>
						<hr />
						<h2 id="reading">Reading</h2>
						<div class="accordion">
							<div
								v-for="(item, index) in reading"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2
									class="accordion-header"
									:id="'reading' + index"
								>
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<h2 id="questions">Questions</h2>
						<p>
							Below are four questions on sourcing and tracing
							clean material and organic matter. Read the sample
							actions and considerations which may help guide you
							on how you would approach dealing with these risks,
							and then enter your plan in the text boxes below.
						</p>
						<div class="block"></div>

						<Question
							boxTitle="Source clean plant material"
							idTitle="one"
							subTitle="New rootstock and budwood"
							radioAcceptance="Rootstock and budwood source and how I
										ensure it is clean:"
							:datas="data.rootstock"
						>
							<p>
								Grow and supply for your own needs on the
								orchard
							</p>
							<p>Source KVH certified plants</p>
							<p>
								Source grafting material from your own orchard
								if possible. Alternatively, source the cleanest
								possible material from KVH registered budwood
								suppliers
							</p>
							<p>
								Choose disease tolerant varieties and those
								which are suitable to your situation. Plan to
								replace less tolerant plants/varieties
							</p>
							<p>
								Trace all plant movements on and off the
								property (rootstock, budwood, flowers, pollen
								etc.) and maintain records
							</p>
						</Question>

						<Question
							boxTitle="Source clean plant material"
							idTitle="two"
							subTitle="Pollen"
							radioAcceptance="Pollen source and how I ensure it is
										clean:"
							:datas="data.pollen"
						>
							<p>Have sufficient pollinators on-orchard</p>
							<p>Ideally, collect and mill own pollen on site</p>
							<p>
								Source pollen from the cleanest possible source.
								This must be a KVH
								<a
									href="https://kvh.org.nz/protocols-movement-controls/bees-and-pollen"
									target="_blank"
									>registered pollen provider</a
								>
							</p>
						</Question>

						<Question
							boxTitle="Source clean plant material"
							idTitle="three"
							subTitle="Compost and organic fertilisers"
							radioAcceptance="Compost and organic fertiliser source
										and how I ensure it is clean:"
							:datas="data.fertilizers"
						>
							<p>
								May contain plant material which hasn’t been
								composted thoroughly and poses a risk of disease
								transference:
							</p>
							<ul class="read">
								<li>Use reputable suppliers</li>
								<li>
									A visitor register explaining requirements
									and instructions
								</li>
								<li>
									Only use compost that is free of kiwifruit
									plant material or is from a KVH approved
									compost provider
								</li>
							</ul>
						</Question>

						<Question
							boxTitle="Source clean plant material"
							idTitle="four"
							subTitle="Other plant material"
							radioAcceptance="Plant material of other species, where I
										source these and how I ensure they are
										clean:"
							:datas="data.other"
						>
							<p>
								Diseases or pests may be introduced through
								other plants e.g. shelter plants and other
								crops. Assess risk of incoming plant material
								and ensure suppliers provide verification of
								freedom from biosecurity threats. Keep records
								of this.
							</p>
						</Question>

						<br />
						<br />

						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-4"
							name="step3"
							class="large"
						></Buttons>

						<hr />
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, computed, onMounted } from "vue";

	import Buttons from "../../components/Buttons.vue";
	import PageNav from "../../components/PageNav.vue";
	import Layout from "./planContainer.vue";

	import Question from "../../components/Question3.vue";

	export default {
		setup() {
			const store = useStore();

			const status = computed(() => store.state.create.step3.status);

			const data = computed(() => store.state.create.step3.data);
			const datas = computed(() => store.state.create.step3);
			const reading = computed(() => store.state.create.step3.reading);
			const plan = computed(() => store.state.create.plan);
			const isComplete = computed(
				() => store.getters["create/step3/isComplete"]
			);

			const state = reactive({
				name: "STEP 3: SOURCE AND TRACE CLEAN PLANT MATERIAL",
			});

			onMounted(() => {
				store.dispatch("create/getsPlan", "whatAndWhy");
			});
			return {
				state,
				status,
				reading,
				datas,
				isComplete,
				data,
				plan,
			};
		},
		components: {
			Buttons,
			PageNav,
			Layout,
			Question,
		},
	};
</script>

<style lang="scss">
	.form-div {
		margin-bottom: 3rem;
		ul > li {
			list-style: none;
			padding-left: 1rem;
			margin-bottom: 0.75rem;
		}
	}
</style>
