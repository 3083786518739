<template lang="">
  <div class="row plans-list">
    <div class="col-xs-12">
      <div class="loading">
        <div class="card row">
          <div class="col-xl-9">
            <div class="row">
              <div class="col-sm-5">
                <p class="d-none d-lg-block">Name</p>
                <h2>{{ loading }}</h2>
              </div>
              <div class="col-md-4 d-none d-md-block">
                <p>Date sumbitted</p>
                {{ loading }}
              </div>
              <div class="col-md-3 mb-hide">
                <p class="d-none d-lg-block">Status</p>
                {{ loading }}
              </div>
            </div>
            <p>
              {{ loading }}
            </p>
          </div>
          <div class="col-xl-3"><Options> </Options></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import Options from "./Options.vue";
	export default {
		data() {
			return {
				loading: ".",
			};
		},
		mounted() {
			this.interval = setInterval(() => {
				if (this.loading.length < 3) {
					this.loading = this.loading + ".";
				} else {
					this.loading = ".";
				}
			}, 420);
		},
		unmounted() {
			clearInterval(this.interval);
		},
		methods: {},
		components: {
			Options,
		},
	};
</script>
<style lang="scss"></style>
