<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>

					<p>
						Read through the readings below, then proceed down to
						the bottom of the page and answer the questions to
						complete this section.
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#reading"> Reading</router-link>
							<ul>
								<li
									v-for="(item, index) in reading"
									:key="index"
								>
									<router-link :to="'#reading' + index"
										>{{ item.head }}
									</router-link>
								</li>
							</ul>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.property.applicable == true &&
											data.property.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#one"
										>Property access</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.tools.applicable == true &&
											data.tools.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#two"
										>Tools and equipment</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.vehicles.applicable == true &&
											data.vehicles.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#three"
										>Vehicles and machinery</router-link
									>
								</li>

								<li>
									<span
										v-if="
											data.bins.applicable == true &&
											data.bins.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#four"
										>Harvest bins</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.staff.applicable == true &&
											data.staff.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#five">
										Visitors and staff</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.imported.applicable == true &&
											data.imported.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#six"
										>Imported fruit</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.protection.applicable ==
												true &&
											data.protection.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#seven"
										>Crop protection</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.dispose.applicable == true &&
											data.dispose.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#eight"
										>Remove and dispose of infected
										material</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.wild.applicable == true &&
											data.wild.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#nine"
										>Wild Kiwifruit</router-link
									>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>

				<div class="col-lg-8">
					<form class="form" id="step1">
						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-5"
							name="step4"
							class="large"
						></Buttons>
						<hr />
						<h2 id="reading">Reading</h2>
						<div class="accordion">
							<div
								v-for="(item, index) in reading"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2
									class="accordion-header"
									:id="'reading' + index"
								>
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<h2 id="questions">Questions</h2>
						<p>
							Below are a series of questions on different types
							of orchard inputs and how you can manage the risk
							they pose. Read the sample actions and
							considerations which may help to guide you on how
							you would approach dealing with these risks, and
							then enter your plan in the text boxes below.
						</p>
						<div class="block"></div>

						<Question
							boxTitle="Check and clean"
							idTitle="one"
							subTitle="Property access"
							radioAcceptance="How I manage access to my orchard:"
							:datas="data.property"
						>
							<p>Manage access to the property</p>
							<ul>
								<li>Limit the number of access points</li>
								<li>
									Put signage up to communicate biosecurity
									expectations
								</li>
								<li>Have a designated parking area</li>
							</ul>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="two"
							subTitle="Tools and equipment"
							radioAcceptance="How I manage the risk of tools and equipment entering my orchard and keep them clean:"
							:datas="data.tools"
						>
							<p>
								Sanitise all tools coming on to orchard
								(dedicated tools where possible) using effective
								and recommended sanitisers
							</p>
							<p>
								Don’t take risks by creating wounds in wet
								weather
							</p>
							<p>
								Clean tools at least between rows and at breaks
							</p>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="three"
							subTitle="Vehicles and machinery"
							radioAcceptance="How I manage the risk of vehicles and machinery entering my orchard:"
							:datas="data.vehicles"
						>
							<p>
								Vehicles and machinery free of soil and plant
								material:
							</p>
							<ul>
								<li>
									high-risk vehicles and machinery sanitised
								</li>
								<li>
									use signage at access points to direct
									vehicles to designated parking/hygiene
									control areas
								</li>
								<li>
									allow only essential vehicles into the
									production area
								</li>
								<li>
									limit access to established roads and tracks
								</li>
								<li>
									provide a wash-down area for high-risk
									vehicles
								</li>
							</ul>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="four"
							subTitle="Harvest bins"
							radioAcceptance="How I manage the risk of harvest bins entering my orchard:"
							:datas="data.bins"
						>
							<p>
								Ensure only clean and sanitised bins come on to
								the orchard and check to see they don’t contain
								any leaf/plant material
							</p>
							<p>Clear loadout areas of weeds before harvest</p>
							<p>Follow movement controls in place</p>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="five"
							subTitle="Visitors and staff"
							radioAcceptance="How I manage the risk of visitors and staff entering my orchard:"
							:datas="data.staff"
						>
							<p>
								All footwear cleaned and sanitised prior to
								entry:
							</p>
							<ul>
								<li>
									provide handwashing facilities, footwear
									cleaning and sanitising options (footbath,
									sanitiser spray)
								</li>
								<li>
									alternatively, provide clothing and footwear
									for visitors/staff to wear on orchard
								</li>
							</ul>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="six"
							subTitle="Imported fruit"
							radioAcceptance="How I manage the risk of imported fruit entering my orchard:"
							:datas="data.imported"
						>
							<p>Never bring imported fruit onto the orchard</p>
							<p>
								Provide measures to ensure workers and visitors
								do not discard fruit near vines
							</p>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="seven"
							subTitle="Crop protection"
							radioAcceptance="How I keep on top of crop protection:"
							:datas="data.protection"
						>
							<p>Keep on top of crop protection</p>
							<p>
								Regular protectant programmes should match
								orchard risk and comply with National Pest
								Management Plans. For Psa apply at least one
								approved, effective, Psa protectant per year
							</p>
							<p>
								Use industry approved products (from the Crop
								Protection Standard or KVH recommended product
								list) at label rates
							</p>
							<p>
								Comply with requirements where orchards have
								been identified with resistance
							</p>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="eight"
							subTitle="Remove and dispose of infected material"
							radioAcceptance="How I remove and dispose of infected material:"
							:datas="data.dispose"
						>
							<p>
								Identify and cut-out infected material regularly
							</p>
							<p>
								Dispose (bury or burn on-site) well away from
								water sources, nurseries and production areas
							</p>
							<p>Follow any protocols in place for disposal</p>
							<p>
								Follow any movement controls in place for plant
								material
							</p>
						</Question>

						<Question
							boxTitle="Check and clean"
							idTitle="nine"
							subTitle="Prevent the spread of Wild Kiwifruit"
							radioAcceptance="How I manage unpicked fruit and dispose of removed plant material, including trunks, roots or leaders:"
							:datas="data.wild"
						>
							<p>
								Following harvest, remove all fruit from vines
							</p>
							<p>
								Dropping unpicked fruit to the ground and
								mulching will assist the composting process and
								prevent mass-feeding by birds (such as
								white-eyes) over winter months
							</p>
							<p>
								Never dispose of removed plant material into any
								adjacent gully or unmanaged area
							</p>
						</Question>

						<br />
						<br />

						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="step-5"
							name="step4"
						></Buttons>

						<hr />
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, computed, onMounted } from "vue";
	import Layout from "./planContainer.vue";

	import Buttons from "../../components/Buttons.vue";
	import PageNav from "../../components/PageNav.vue";
	import Question from "../../components/Question.vue";

	export default {
		setup() {
			const store = useStore();

			// const status = computed(() => store.state.create.step1.status);

			const data = computed(() => store.state.create.step4.data);
			const datas = computed(() => store.state.create.step4);
			const reading = computed(() => store.state.create.step4.reading);
			const plan = computed(() => store.state.create.plan);
			const isComplete = computed(
				() => store.getters["create/step4/isComplete"]
			);

			const state = reactive({
				name: "STEP 4: CHECK AND CLEAN",
			});

			onMounted(() => {
				store.dispatch("create/getsPlan", "whatAndWhy");
			});
			return {
				state,
				status,
				reading,
				datas,
				isComplete,
				data,
				plan,
			};
		},
		components: {
			Buttons,
			PageNav,
			Question,
			Layout,
		},
	};
</script>

<style lang="scss">
	.form-div {
		margin-bottom: 3rem;
		ul > li {
			list-style: none;
			padding-left: 1rem;
			margin-bottom: 0.75rem;
		}
	}
</style>
