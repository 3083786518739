import axios from 'axios'
export default {
	namespaced: true,
	state: {
		name: 'nameAndInfo',
		response: {},
		errorKpin: "",
		data: {
		},
		fallback: {
			fullName: '',
			pmoNumber: '',
			kPins: [],
			reminder: '',
			declaration: false,
			isComplete: false,
			planStatus: 0
		},

		// all the data
	},
	mutations: {
		setKpin(state, value) {
			if (Array.isArray(state.data.kPins)) {
				state.data.kPins.push(value)
			} else {
				state.data.kPins = [value]
			}
		},

		unsetKpin(state, value) {
			state.data.kPins.splice(value, 1)
		},
		errorKpin(state, value) {
			state.errorKpin = value;
		},

		setComplete(state) {
			state.isComplete = true
		},

		setPlan(state, value) {
			state.data = value
		},
		setError(state, e) {
			state.response.message = e
			state.response.status = 'error'
		},
		setLoading(state, value) {
			state.response.isLoading = value
		},
	},
	actions: {
		addKpins({
			commit,
			state
		}, value) {
			
			commit('errorKpin', false)

			if (Array.isArray(state.data.kPins) && state.data.kPins.includes(value)) {
				commit('errorKpin', "The Kpin already exists, please try another")
			} else {				
				if (value.toString().length === 4) {
					commit('setKpin', value)					
				} else {
					commit('errorKpin', "Kpin must be 4 digit number, please try again")					
				}
			}
		},
		oldKpin({commit}) {			
			commit('errorKpin', "")			
		},
		removeKpins({
			commit,
			state
		}, value) {
			commit('unsetKpin', value)
		},
		complete({
			commit,
			state
		}, value) {
			commit('setComplete', value)
		},
	},
	getters: {
		// filter state before component

		isComplete: ({data}) => {
			if (data.fullName && data.kPins.length && data.declaration === true) {
				return true
			}
			else {
				return false
			}
		},
	},
}