// Setting Sesson

Storage.prototype.getAuth = function () {
	const userId = this.getItem("userId");
	const authToken = this.getItem("authToken");
	const isAdmin = this.getItem("isAdmin");
	const accessToken = this.getItem("accessToken");

	if (userId == "" || accessToken == "") {
		document.location.href = process.env.VUE_APP_LOGIN_URL;
	}

	return {
		userId,
		accessToken,
		authToken,
		isAdmin
	};
};
var IDLE_TIMEOUT = 60*60; //seconds
var _idleSecondsCounter = 0;
document.onclick = function () {
	_idleSecondsCounter = 0;
};
document.onmousemove = function () {
	_idleSecondsCounter = 0;
};
document.onkeypress = function () {
	_idleSecondsCounter = 0;
};

var myInterval = window.setInterval(CheckIdleTime, 1000);

function CheckIdleTime() {
	_idleSecondsCounter++;
	if (_idleSecondsCounter >= IDLE_TIMEOUT) {
		
		let { userId } = sessionStorage.getAuth();
		let oldUser = userId

		sessionStorage.setItem("userId", "")
		sessionStorage.setItem("accessToken", "")
		sessionStorage.setItem("isAdmin", "")
		sessionStorage.setItem("authToken", "")		
		document.location.href = process.env.VUE_APP_API_URL+"/user/"+oldUser+"/logout";
		window.clearInterval(myInterval);
	}
}



Storage.prototype.isLoggedIn = function () {
	const userId = this.getItem("userId");
	const decoded = this.getItem("decoded");

	return userId == "" || accessToken == "";
};