import axios from 'axios'
import {
	useRoute
} from 'vue-router';
export default {
	namespaced: true,
	state: {
		// all the data
		name: 'plans',
		response: {},

		search: '',
		startDate: null,
		endDate: null,
		data: [],
	},
	mutations: {
		setPlans(state, data) {
			state.data = data
		},
		setError(state, e) {
			state.response = {e}
			state.response.status = 'error'
			console.log(e)

			
		},
		setLoading(state, value) {
			state.response.isLoading = value
		},
		noPlans(state) {
			state.response.noPlans = true
		},
		download(state, value) {
			state.download = value
		},
		setUser(state, value) {
			state.user = value
		}
	},
	actions: {
		// async and do mutations
		async getPlans({
			commit, dispatch
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)
			const { userId, accessToken } = sessionStorage.getAuth();
			
			// if (accessToken || userId) {
			if (userId) {
				try {
					
					const route = useRoute();
				
					const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId+`/plans`, {
						headers: {
							"Authorization": `Bearer ${accessToken}`
						}
					})
					const data = await res.data.data.plans
					
					const user = await res.data.data.user
					commit("setUser", user)
					const submittedPlans = data.filter((plan) => {
						let result = plan.planStatus.toString().includes(1)
						return result
					})
					const draftPlans = data.filter((plan) => {
						let result = plan
						// let result = plan.planStatus.toString().includes(0)
						return result
					})
	
					if (res.data.data.plans.length>0 ) {
						if (value == "submitted") {
							commit('setPlans', submittedPlans)
						}
						else {
							commit('setPlans', draftPlans)
						}
					} else {
						commit('noPlans')
					}
				} catch (e) {

					commit('setLoading', false)
					commit('setError', e.response)
					if (e.response.status == 400) {
						let { userId } = sessionStorage.getAuth();
						try {
							const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId + '/logout')
							document.location.href = process.env.VUE_APP_WEBSITE_URL+"/logout/";				
						} catch (e) {
							document.location.href = process.env.VUE_APP_WEBSITE_URL+"/logout/";				
							
						}										
					}
				}
			}

			else {
				commit("setError", "Seems you've logout, Please login to save plans")
			}


			commit('setLoading', false)
		},
		
		async getAllPlans({
			commit,
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)
			const { userId, accessToken } = sessionStorage.getAuth();
			
			
			// if (accessToken || userId) {
			if (userId) {
				try {
					
					const route = useRoute();
				
					const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId+`/plans/all`, {
						headers: {
							"Authorization": `Bearer ${accessToken}`
						}
					})
					
					const data = await res.data.data.plans
					const submittedPlans = data.filter((plan) => {
						let result = plan.planStatus.toString().includes(1)
						return result
					})
					// commit("setUser", user)
					
					commit('setPlans', submittedPlans)
	
					
				} catch (e) {
					commit('setError', e)
					commit('setLoading', false)
				}
			}

			else {
				commit("setError", "Seems you've logout, Please login to save plans")
			}


			commit('setLoading', false)
		},
		

		async deletePlan({
			commit,
			state,
			dispatch
		}, id) {
			// commit('deletePlan', id)
			const { userId, accessToken } = sessionStorage.getAuth();
			if (userId) {
				try {
					const res = await axios.delete(process.env.VUE_APP_API_URL + `/plan/${id}`, {
						headers: {
							"Authorization": `Bearer ${accessToken}`
						}
					})
					dispatch("getPlans")
					// try {
					// 	const res = await axios.get(process.env.VUE_APP_API_URL + `/plan/user/1`)
					// 	const data = await res.data.data.plans
					// 	commit('setPlans', data)
					// } catch (e) {
					// 	commit('setError', e)
					// }
				} catch (e) {
					commit('setError', e)
				}
			} else {
				commit("setError", "Seems you've logout, Please login to save plans")
			}

		},

		async getUser({
			commit,
			state
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)
			const { userId, accessToken } = sessionStorage.getAuth();
			
			if (userId) {

				try {
					const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + value.data.planId + `/downloadAll`, {
						headers: {
							"Authorization": `Bearer ${accessToken}`
						}
					})
					const data = await res.data
					commit('downloadZip', data)
				} catch (e) {
					commit('setLoading', false)
					commit('setError', e)
				}
				
				
				commit('setLoading', false)
			}
			else {
				commit("setError", "Seems you've logout, Please login to save plans")
			}
		},

		async getKpinLinkZip({
			commit,
			state
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)

				const { userId, accessToken } = sessionStorage.getAuth();
			try {
				const res = await axios.get(process.env.VUE_APP_API_URL + `/plan/` + value.id + `/downloadAll`, {
					responseType: 'blob',
					headers: {
						"Authorization": `Bearer ${accessToken}`
					}
				})
				 	const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'all_kpins.zip');
					document.body.appendChild(link);
					link.click();
				// const data = await res.data
				// commit('downloadZip', data)
			} catch (e) {
				commit('setLoading', false)
				commit('setError', e)
			}
			
			
			var modal = document.querySelector('.modal-backdrop')
			if (modal) {
				modal.style.display = "none"
				modal.remove()
			}

			commit('setLoading', false)
		},
		async getKpinLinkMaster({
			commit,
			state
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)

				const { userId, accessToken } = sessionStorage.getAuth();
			try {
				const res = await axios.get(process.env.VUE_APP_API_URL + `/plan/` + value.id + `/downloadMaster`, {
					responseType: 'blob',
					headers: {
						"Authorization": `Bearer ${accessToken}`
					}
				})
				 	const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'master-pdf.pdf');
					document.body.appendChild(link);
					link.click();
				// const data = await res.data
				// commit('downloadZip', data)
			} catch (e) {
				commit('setLoading', false)
				commit('setError', e)
			}
			var modal = document.querySelector('.modal-backdrop')
			if (modal) {
				modal.style.display = "none"
				modal.remove()
			}

			commit('setLoading', false)
		},
		async getKpinLink({
			commit,
			state
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)

				const { userId, accessToken } = sessionStorage.getAuth();
			try {
				const res = await axios.get(process.env.VUE_APP_API_URL + `/plan/` + value.data.planId + `/download/`+value.kPin, {
					responseType: 'blob',
					headers: {
						"Authorization": `Bearer ${accessToken}`
					}
				})
				 	const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `Kpin-${value.kPin}.pdf`);
					document.body.appendChild(link);
					link.click();
				// const data = await res.data
				// commit('downloadZip', data)
			} catch (e) {
				commit('setLoading', false)
				commit('setError', e)
			}
			var modal = document.querySelector('.modal-backdrop')
			if (modal) {
				modal.style.display = "none"
				modal.remove()
			}

			commit('setLoading', false)
		},



	},
	getters: {
		// filter state before component
		searchedPlans: ({ startDate, endDate, search, data: _data }) => {
			let data = _data;
			data.sort((a, b) => {
				let aDate = new Date(a.submittedAt).getTime() 
				let bDate = new Date(b.submittedAt).getTime()
				return bDate - aDate
			})
			data.sort((a, b) => {
				
				return a.planStatus - b.planStatus
			})
			if (search) {
				data = data.filter((plan) => {
					let result = plan.fullName.toLowerCase().includes(search.toLowerCase())
					result = result || plan.reminder.toLowerCase().includes(search.toLowerCase())
					result =
						result ||
						plan.kPins.some((kPin) => {
							return kPin.toLowerCase().includes(search.toLowerCase())
						})
					return result
				})
			} 

			if (startDate || endDate) {
				data = data.filter((plan) => {
					const dated = new Date(plan.submittedAt);					let result = !startDate || (
						startDate
						&& dated.getTime() >= startDate.getTime()
					);
					result &= !endDate || (
						endDate
						&& dated.getTime() <= endDate.getTime()
					);

					return result;
				})
			}
			
			return data;
		}
	},
}