<template lang="">
  <div
    class="col-lg-3 page-nav"
  >
    <div>
      <h3>On this page</h3>
      <slot></slot>
    </div>
  </div>
</template>
<script>
	export default {};
</script>
<style lang="scss">
	@import "../assets/scss/mixins.scss";
	.page-nav {
		position: absolute;
		right: 0;
		height: 100%;
		@include tab {
			position: relative;
		}

		div {
			position: sticky;
			top: 6rem;
			right: 0;

			ul {
				padding-left: 0;

				li {
					list-style: none;
					position: relative;

					margin: 0.75rem 0;
				}

				ul {
					padding-left: 0;

					li {
						padding-left: 20px;
					}
				}
			}

			a {
				text-decoration: none;
				// text-transform: capitalize;

				@include line-clamp(1.5rem, 2);

				&::first-letter {
					text-transform: uppercase;
				}
			}

			.tick {
				position: absolute;
				top: -2px;
				left: 0;

				i {
					border-radius: 40px;
					border: 1px solid #ccc;
					color: #ccc;
					font-size: 8px;
					padding: 2px;
				}

				&.accepted {
					i {
						color: $dark-green;
						border-color: $dark-green;
					}
				}
			}
		}
	}
</style>
