export default {
	namespaced: true,
	state: {
		name: 'step4',
		status: 'draft',
		isComplete: false,

		response: {
			status: 'true/false',
			message: 'lorem ipsum',
		},

		reading: [{
				head: 'Pests and pathogens can survive in small amounts of soil',
				body: `<p>Growers should check and be comfortable that inputs crossing their orchard boundary do not present a risk to their investment. Pests and pathogens can survive in small amounts of soil or plant material (e.g., a teaspoonful of soil or single piece of budwood), so any item that may be contaminated from another orchard or location could be transporting a biosecurity threat. </p>
<p>Tools that cut into the tissue of a plant are the greatest risk (e.g., pruning and girdling), creating an entry point for pests and pathogens to enter. </p>
<p>People can transport pests and pathogens on clothing, hands, footwear and other personal items.</p>
<p> Footwear is considered the greatest risk and can easily spread contaminated soil from one site to another. </p>
<p>All visitors should have clean footwear and additional measures may be warranted for high-risk visitors.</p>`,
				accepted: false,
			}
		],

		data: {
			property: {
				content: '',
				applicable: true,
			},
			tools: {
				content: '',
				applicable: true,
			},
			vehicles: {
				content: '',
				applicable: true,
			},
			bins: {
				content: '',
				applicable: true,
			},

			staff: {
				content: '',
				applicable: true,
			},
			imported: {
				content: '',
				applicable: true,
			},
			protection: {
				content: '',
				applicable: true,
			},
			dispose: {
				content: '',
				applicable: true,
			},
			wild: {
				content: '',
				applicable: true,
			},
			isComplete: false
		},
		fallback: {

			property: {
				content: '',
				applicable: true,
			},
			tools: {
				content: '',
				applicable: true,
			},
			vehicles: {
				content: '',
				applicable: true,
			},
			bins: {
				content: '',
				applicable: true,
			},

			staff: {
				content: '',
				applicable: true,
			},
			imported: {
				content: '',
				applicable: true,
			},
			protection: {
				content: '',
				applicable: true,
			},
			dispose: {
				content: '',
				applicable: true,
			},
			wild: {
				content: '',
				applicable: true,
			},
			isComplete: false
		},
		// all the data
	},
	mutations: {
		// change state
		setComplete(state, value) {
			state.data.isComplete = true
		},

		setPlan(state, value) {
			state.data = value
		},
	},
	actions: {
		// async and do mutations
		complete({
			commit,
		}, value) {
			commit('setComplete', value)
		},
	},
	getters: {
		isComplete: (state) => {
			let dataKeys = Object.keys(state.data)

			dataKeys = dataKeys.filter(data => data !== 'isComplete'); 
			
			let result = dataKeys.every((dataKey) => {
				let data = state.data[dataKey]
				if (data.applicable) {
					return data.content.length > 0
				} else {
					return true
				}
			})
			state.data.isComplete = result
			return result
		},
	},
}