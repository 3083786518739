import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import moment from 'moment';
require("./session.js")

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
// import "./toruskit";

import './assets/scss/global.scss'
moment.locale('nz');


const app = createApp(App)
app.config.globalProperties.$filters = {
  formatedDate(date) {
    return moment(date).format("DD/MM/YYYY")
  },
}

app.use(router).use(store)

app.mount('#app')

const tx = document.getElementsByTagName("textarea");
for (let i = 0; i < tx.length; i++) {
  tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
  tx[i].addEventListener("input", OnInput, false);
}

function OnInput() {
  this.style.height = "auto";
  this.style.height = (this.scrollHeight) + "px";
}
