<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>
					<p>
						Open each explanation below by clicking on it, once
						you’ve read the explanation check the ‘I have read’ box
						and continue. You can ‘Save’ your plan
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#explanations"
								>explanations</router-link
							>
							<ul>
								<li
									v-for="(item, index) in questions"
									:key="{ index }"
								>
									<span
										class="tick"
										:class="{ accepted: data[item.alias] }"
									>
										<i class="fa fa-check"></i>
									</span>
									<router-link :to="'#heading' + index">{{
										item.head
									}}</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="nameAndInfo">
						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-1"
							name="whatAndWhy"
							class="large"
						></Buttons>
						<hr />
						<h2 id="explanations">Explanations</h2>

						<br />
						<div class="accordion">
							<div
								v-for="(item, index) in questions"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2 class="accordion-header">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
										<br />
										<br />
										<div class="form-check">
											<input
												class="form-check-input"
												type="checkbox"
												:id="'check-' + index"
												v-model="data[item.alias]"
											/>
											<label
												class="form-check-label"
												:for="'check-' + index"
											>
												I have read and understand the
												above text
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<br />
						<br />
						<Buttons
							:datas="datas"
							:status="isComplete"
							next="step-1"
							name="whatAndWhy"
							:id="plan.data.planId"
						></Buttons>
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, computed, onMounted } from "vue";

	import Layout from "./planContainer.vue";

	import Buttons from "../../components/Buttons.vue";
	import Kpins from "../../components/Kpins.vue";
	import PageNav from "../../components/PageNav.vue";

	export default {
		setup() {
			const store = useStore();

			const status = computed(() => store.state.create.whatAndWhy.status);

			const questions = computed(() => store.state.create.whatAndWhy.options);
			const data = computed(() => store.state.create.whatAndWhy.data);
			const datas = computed(() => store.state.create.whatAndWhy);
			const plan = computed(() => store.state.create.plan);

			const main = computed(() => store.state.create.steps);

			const isComplete = computed(
				() => store.getters["create/whatAndWhy/isComplete"]
			);
			// const save = () => store.plan.commit("saveForm", data);

			const state = reactive({
				name: "What & Why",
			});

			onMounted(() => {
				store.dispatch("create/getsPlan", "whatAndWhy");
			});
			return {
				state,
				status,
				main,
				data,
				datas,
				isComplete,
				questions,
				plan,
			};
		},
		components: {
			Buttons,
			Kpins,
			PageNav,
			Layout,
		},
	};
</script>

<style lang="scss">
	.accordion-item {
		margin-bottom: 15px;
		border: none;
		box-shadow: 0px 3px 6px #ddd;
	}
</style>
