<template>
	<Layout>
		<div class="page no-page-nav">
			<div class="row">
				<div class="col-lg-8">
					<div class="page-title">
						<h1>
							{{ title }}
						</h1>
						<h2 class="welcome">
							Welcome {{ user.firstName }} {{ user.lastName }}
						</h2>

						<h2>5 steps to strengthen on-orchard biosecurity</h2>
						<p>
							Biosecurity is about protecting New Zealand from the
							risks posed by unwanted pests and diseases. All 5
							million of us are part of our biosecurity system,
							protecting New Zealanders, our health and way of
							life, our natural and productive resources and our
							biodiversity.
						</p>
						<p>
							Growers with good on-orchard biosecurity practice
							give themselves the best chance of preventing new
							pests and diseases entering their orchard. This
							means they may also avoid the impacts that these
							unwanted threats bring.
						</p>
						<NewPlan></NewPlan>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { ref, computed, onMounted } from "vue";
	import { useStore } from "vuex";
	import Layout from "./mainContainer.vue";
	import NewPlan from "../components/NewPlanBtn.vue";
	export default {
		setup() {
			const title = ref("YOUR ON-ORCHARD BIOSECURITY PLAN");
			const store = useStore();
			let user = computed(() => store.state.user);
			let users = computed(() => store.state.isAdmin);
			const { userId, authToken } = sessionStorage.getAuth();
			onMounted(() => {
				store.dispatch("getTheUser");
				store.dispatch("getTheAdmin");
			});

			return {
				title,
				user,
				users,
			};
		},

		components: {
			Layout,
			NewPlan,
		},
	};
</script>

<style lang="scss"></style>
