<template>
	<Layout>
		<div class="page no-page-nav">
			<div class="row">
				<div class="col-lg-6">
					<div class="page-title">
						<h1>{{ name }}</h1>

						<p>
							Use the search bar below to look for names, logins,
							and KPIN's associated with the plan you are
							searching for.
						</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
					<div class="filter">
						<div class="row">
							<div class="col-lg-6">
								<div class="form-floating mb-3">
									<input
										type="text"
										class="form-control"
										id="floatingInput"
										placeholder="name@example.com"
										v-model="state.search"
									/>
									<label for="floatingInput">Search</label>
								</div>
							</div>
							<div class="col-lg-3 form-floating">
								<datepicker
									class="form-control datePicker"
									clearable="true"
									v-model="state.startDate"
									:upperLimit="state.endDate"
									placeholder="start date"
									inputFormat="dd-MM-yyyy"
									typeable
								/>
							</div>
							<div class="col-lg-3 form-floating">
								<datepicker
									class="form-control datePicker"
									clearable="true"
									v-model="state.endDate"
									placeholder="end date"
									typeable
									:lowerLimit="state.startDate"
									inputFormat="dd-MM-yyyy"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xs-12">
					<Loading v-if="state.response.isLoading"> </Loading>

					<PlansList v-else>
						<Error
							v-if="state.response.status == 'error'"
							:message="state.response.message"
						>
						</Error>

						<div v-else-if="state.response.noPlans">
							<h4>
								You currently have no plans. Start a new plan
							</h4>
						</div>
					</PlansList>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { computed, ref, onMounted } from "vue";
	import Layout from "./mainContainer.vue";

	import datepicker from "vue3-datepicker";
	import PlansList from "../components/PlansTable.vue";
	import Loading from "../components/Loading.vue";
	import Error from "../components/Error.vue";

	export default {
		setup() {
			const name = ref("Search Plans");
			const sdate = ref("");
			const edate = ref("");
			const store = useStore();
			const state = computed(() => store.state.plans);
			const startDate = computed(() => {
				store.state.plans.startDate;
			});
			const startD = computed(() => store.getters["plans/getDate"]);
			const endDate = computed(() => store.state.plans.endDate);

			onMounted(() => {
				const { isAdmin } = sessionStorage.getAuth();

				if (isAdmin) {
					store.dispatch("plans/getAllPlans", "submitted");
					console.log("hera na");
				} else {
					store.dispatch("plans/getPlans", "submitted");
					console.log("hera na Hera");
				}

				// console.log("picker: " + JSON.stringify(datepicker));
			});
			return {
				name,
				state,
				startDate,
				sdate,
				endDate,
				edate,
				startD,
			};
		},
		components: {
			PlansList,
			Loading,
			Error,
			datepicker,
			Layout,
		},
	};
</script>

<style lang="scss">
	.datePicker {
		height: calc(3.5rem + 2px);
		padding: 1rem 0.75rem;
	}
</style>
