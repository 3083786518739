
export default {
namespaced: true,
	state: {
		name: 'step1',
		status: 'draft',
		response: {
			status: "true/false",
			message: "lorem ipsum"
		},
		
		reading: [
			{
				head: "Only by understanding the risk can we act to protect our investment",
				body: `<p>Only by understanding the risk can we act to protect our investment. </p>
<ul>
	<li>50% vine loss: in Brazilian kiwifruit orchards because of <i>Ceratocystis wilt</i>.</li>
	<li>6 months plus: the time it could take between infection with a soil-borne disease like <i>Ceratocystis wilt</i> to when symptoms appear.</li>
	<li> $430 million: the potential market access impact of a fruit fly incursion in Te Puke. </li>
</ul>
<p>Psa is not the only threat to kiwifruit orchards. There are many pests and diseases identified offshore that could impact our ability to grow or sell kiwifruit should they arrive here. KVH maintains a pest list online at www.kvh.org.nz with almost 100 biosecurity risks to kiwifruit. Listed here are examples of our most unwanted.</p>`,
				accepted: false,
			},
			{
				head: "'Kiwifruit's Most Unwanted'",
				body: `<div class="row">
	<div class="col">
		<h4>Fruit Flies</h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Market access restrictions</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			<li>Movement of infested fruit</li>
		</ul>
	</div>
	<div class="col">${"<img src='"+require('../../assets/images/flies.png')+"' alt=''>"}
	</div>
</div>
<div class="row">
	<div class="col">
		<h4><i>Ceratocystis wilt</i></h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Production impacts - up to 50% vine loss</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			<li>Tools </li>
			<li>Plant material</li>
			<li>Soil on people or vehicles and equipment</li>
		</ul>
	</div>
	<div class="col"> ${"<img src='"+require('../../assets/images/cero.png')+"' alt=''>"}
	</div>
</div>

<div class="row">
	<div class="col">
		<h4>Brown Marmorated Stink Bug</h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Production impacts - fruit loss, control costs and residue issues for markets</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			<li>Imported vehicles and machinery</li>
			<li>Visitor’s luggage</li>
			<li>Shipping containers</li>
			<li>Internet purchases</li>
		</ul>
	</div>
	<div class="col"> ${"<img src='"+require('../../assets/images/bmsb.png')+"' alt=''>"}
	</div>
</div>


<div class="row">
	<div class="col">
		<h4>Spotted Lanternfly</h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Production impacts – mainly from sooty mould. Hitchhiker pest, so hard to control sp read</p>
		<p class="underline"> How might this enter my orchard?</p>
		<ul>
			<li>Eggs on imported vehicles, machinery or structural materials</li><li>Shipping containers</li>
			<li>Visitor’s luggage</li>
		</ul>
	</div>
	<div class="col"> ${"<img src='"+require('../../assets/images/lanternfly.png')+"' alt=''>"}
	</div>
</div>



<div class="row">
	<div class="col">
		<h4>Psa (non-New Zealand biovars)</h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Impacts to green varieties and possibly new gold varieties also</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			<li>Tools</li><li>People</li><li>Plant material</li>
		</ul>
	</div>
	<div class="col"> ${"<img src='"+require('../../assets/images/psa.png')+"' alt=''>"}
	</div>
</div>

<div class="row">
	<div class="col">
		<h4>White Peach Scale</h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Production impacts - fruit loss and control costs</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			<li>Imported fruit being brought onto the orchard</li>
		</ul>
	</div>
	<div class="col"> ${"<img src='"+require('../../assets/images/peach.png')+"' alt=''>"}
	</div>
</div>
<div class="row">
	<div class="col">
		<h4>Yellow Spotted Stink Bug</h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Significant production impacts and fruit loss, very similar to the Brown Marmorated Stink Bug</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			<li>Imported vehicles and machinery</li>
			<li>Visitor’s luggage</li>
			<li>Shipping containers</li>
			<li>Internet purchases</li>
		</ul>
	</div>
	<div class="col">
	 ${"<img src='"+require('../../assets/images/yellow_spotted_stink_bug.png')+"' alt=''>"}
	</div>
</div>




<div class="row">
	<div class="col">
		<h4>Invasive <i>phytophthoras</i></h4>
		<p class="underline">How does this affect my orchard?</p>
		<p>Production impacts - plant killers, impacts unknown</p>
		<p class="underline">How might this enter my orchard?</p>
		<ul>
			
			<li>Tools</li><li>Plant material</li><li>Soil on people or vehicles and equipment</li>
		</ul>
	</div>
	<div class="col"> ${"<img src='"+require('../../assets/images/cero.png')+"' alt=''>"}
		
	</div>
</div>
`,
				accepted: false,
			},
		],
		
		data: {
			offshore: {				
				content: "",
				applicable: true,
			},
			local: {
				content: "",
				applicable: true,
			},
			border: {
				content: "",
				applicable: true,
			},
			isComplete: false
		},
		fallback: {
			offshore: {				
				content: "",
				applicable: true,
			},
			local: {
				content: "",
				applicable: true,
			},
			border: {
				content: "",
				applicable: true,
			},
			isComplete: false
		},
		// all the data
	},
	mutations: {
		// change state 
		
		setComplete(state, value) {
			state.data.isComplete = true;
		},
		
		setPlan(state, value) {
			state.data = value;
		},

	},
	actions: {
		// async and do mutations
		
		complete({commit, state}, value) {
			commit('setComplete', value)			
		}

	},
	getters: {
		isComplete: (state) => {
			let dataKeys = Object.keys(state.data)
			dataKeys = dataKeys.filter(data => data !== 'isComplete');
			let result = dataKeys.every((dataKey) => {
				let data = state.data[dataKey] 
				if (data.applicable) {
					return data.content?.length > 0
				}
				else {
					return true

				}
			})
			state.data.isComplete = result
			return result
		}
	},

};
