<template lang="">
  <div v-for="(step, index) in steps" class="col-md-6" style="display: flex">
    <router-link :to="step.link" class="card" :class="{ completed: step.status }">
      <div class="card-body">
        <h3>
          <span class="index">{{ index }}.</span> {{ step.title }} <i class="fa fa-arrow-right"></i>
        </h3>
        <p>{{ step.description }}</p>
        <span class="tick"> <i class="fa fa-check"></i> </span>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['steps'],
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/mixins.scss';
a.card {
  text-decoration: none;
  margin-top: 1.5rem;
  margin-bottom: 0;

  .index {
    opacity: 0.7;
  }
  h3 {
    width: 80%;

    .fa {
      padding-left: 1rem;

      transition: all 0.2s linear;
    }
  }
  .tick {
    position: absolute;
    top: 10px;
    right: 0;
    i {
      border-radius: 40px;
      border: 2px solid #ccc;
      color: #ccc;
      font-size: 11px;
      padding: 3px;
    }
  }

  &:hover {
    h3 .fa {
      padding-left: 1.5rem;
    }
  }
  &.completed {
    .tick {
      i {
        color: $dark-green;
        border-color: $dark-green;
      }
    }
  }
}
</style>
