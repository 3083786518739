import nameAndInfo from './NameAndInfo'
import whatAndWhy from './WhatAndWhy'
import step1 from './Step1'
import step2 from './Step2'
import step3 from './Step3'
import step4 from './Step4'
import step5 from './Step5'
import lastStep from './LastStep'
import axios from 'axios'
import router from '../../router'
import {
	useRoute,
	useRouter
} from 'vue-router'

export default {
	namespaced: true,

	state: {
		response: {
			isLoading: false,
			data: {
				data: {
					status: "",
					error:	{
						code: "",
						message: ""
					}
				},
			},
			status: "",
			statusText: "",
			
		},
		new: true,
		steps: [{
				id: 'nameAndInfo',
				title: 'Name & Info',
				link: 'info',
				status: false,
				description: 'Name, KPIN and declaration details completed by the person responsible for completing this plan.',
			},
			{
				id: 'whatAndWhy',
				title: 'What & Why',
				link: 'what-and-why',
				status: false,
				description: 'Why biosecurity is important and what you  can do to improve on-orchard biosecurity.',
			},
			{
				id: 'step1',
				title: 'Step 1',
				link: 'step-1',
				status: false,
				description: 'Understanding your biosecurity risks so you can protect your investment.',
			},
			{
				id: 'step2',
				title: 'Step 2',
				link: 'step-2',
				status: false,
				description: 'Agreeing what must happen on site so that everyone is on the same page and operating to your plan.',
			},
			{
				id: 'step3',
				title: 'Step 3',
				link: 'step-3',
				status: false,
				description: 'Sourcing and tracing clean plant material to reduce threats on this high-risk pathway.',
			},
			{
				id: 'step4',
				title: 'Step 4',
				link: 'step-4',
				status: false,
				description: "Check and clean so that what comes on to the orchard don't present a risk to your investment.",
			},
			{
				id: 'step5',
				title: 'Step 5',
				link: 'step-5',
				status: false,
				description: 'Reporting the unusual to minimise impacts on orchards, businesses, and livelihoods.',
			},
			{
				id: 'lastStep',
				title: 'What happens after I make a report?',
				link: 'last-step',
				status: false,
				description: 'Tacking action and making a report is a good thing, so is knowing what happens next.',
			},
		],
		plan: {
			planStatus: 0,
			data: {
			},
			fallback: {
				planId: 'new',
				planStatus: 0
			}

		}
	},
	mutations: {
		setError(state, e) {
			state.response.data = e
			// state.response.status = 'error'
			// console.trace();
		},
		setLoading(state, value) {
			state.response.isLoading = value
		},
		setComplete(state, value) {
			state[value.name].data.isComplete = true
		},
		setPlanId(state, value) {
			state.new = false
			
			state.plan.data.planId = value
			
		},
		setSubmit(state, value) {
			state.nameAndInfo.data.planStatus = 1
		},
		setPlan(state, value) {
			
			
			value.plan ? state.plan.data = value.plan : state.plan.data = state.plan.fallback
			value.nameAndInfo ? state.nameAndInfo.data = value.nameAndInfo : state.nameAndInfo.data = {
				fullName: '',
				pmoNumber: '',
				kPins: [],
				reminder: '',
				declaration: false,
				isComplete: false,
				planStatus: 0
			},
			value.whatAndWhy ? state.whatAndWhy.data = value.whatAndWhy : state.whatAndWhy.data = state.whatAndWhy.fallback
			value.step1 ? state.step1.data = value.step1 : state.step1.data = state.step1.fallback
			value.step2 ? state.step2.data = value.step2 : state.step2.data = state.step2.fallback
			value.step3 ? state.step3.data = value.step3 : state.step3.data = state.step3.fallback
			value.step4 ? state.step4.data = value.step4 : state.step4.data = state.step4.fallback
			value.step5 ? state.step5.data = value.step5 : state.step5.data = state.step5.fallback
			value.lastStep ? state.lastStep.data = value.lastStep : state.lastStep.data = state.lastStep.fallback
			
		},

		setSteps(state, value) {
			
			state.new = false

			state.steps.forEach(step => {
				step.status = false
				if (value[step.id].isComplete) {
					step.status = value[step.id].isComplete
				}
			})

		},

		setDuplicatePlan(state, value) {
			this.commit("create/setPlan", {
				...value,
				plan: state.plan.fallback,
				nameAndInfo: {
					fullName: '',
					pmoNumber: '',
					kPins: [],
					reminder: '',
					declaration: false,
					isComplete: false,
					planStatus: 0
				},
				whatAndWhy: {
					whatIsBioSec: false,
					whyIsBioSec: false,
					spread: false,
					improve: false,
					team: false,
					isComplete: false
				}
			})
		},
		toOverview(state, value) {
			router.push("overview");
		},

		copyPlan(state) {
			state.new = "copy";
			("change pland id to:  COPY: "+JSON.stringify(state.new))
			
		},

		newPlan(state, value) {
			state.new = value;
			state.plan.data.planId = "new"
			state.steps.forEach((step) => step.status = false)
		},

	},
	actions: {

		newPlan({
			commit,
		}) {
			commit("setPlan", "value")
			commit("newPlan", true)
		},
		copyPlan({
			commit,
		}, ) {
			commit("copyPlan")
		},
		async getsPlan({
			commit,
			getters,
			state
		}) {

			const route = useRoute()
			const { userId, accessToken } = sessionStorage.getAuth();
			const id = route.params.id
			// const id = userId			
			commit('setPlan', "value")

			if (userId) {

				if (id == "undefined" || id == "new" || id == "copy") {
					
				} else {
					try {
						const res = await axios.get(process.env.VUE_APP_API_URL + `/plan/` + id, {
							headers: {
								"Authorization": `Bearer ${accessToken}`
							}
						})
						const data = await res.data
						commit('setSteps', data.data)
						commit('setPlan', data.data)
						// getters["getComplete"];
	
					} catch (e) {
	
						commit('setLoading', false)
						commit('setError', e.response)
						// if (e.response.status == 400) {
						// 	let { userId } = sessionStorage.getAuth();
						// 	try {
						// 		const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId + '/logout')
						// 		document.location.href = process.env.VUE_APP_WEBSITE_URL+"/logout/";				
						// 	} catch (e) {
								
						// 	}										
						// }
					}

				}
				// commit('newPlan', true)
			}else {
				commit("setError", "Seems you've logout, Please login to save plans") 
			}


			// commit("setLoading", false)
		},

		completeStep({
			commit,
			dispatch,
			
		}, value) {
			commit('setComplete', value.data.datas)
			dispatch("savePlan", {
				data: value.data.datas,
				next: value.data.next,
				id: value.id
			});
		},

		submitPlan({
			commit,
			dispatch,
			
		}, value) {
			commit('setSubmit')

			dispatch("savePlan", {
				data: value.data,
				id: value.id,
				submit: true
			});
		},



		async savePlan({
			commit,
			getters,
			state
		}, value) {
			// to seperate data and post to server
			let { userId, accessToken } = sessionStorage.getAuth();
			let getAuth = sessionStorage.getAuth();
			console.log(JSON.stringify(value))
			if (userId) {
			

				let postData = {}
					// seperating local and data to save in POStaData
					;
				[
					// 'plan',
					'nameAndInfo',
					'whatAndWhy',
					'step1',
					'step2',
					'step3',
					'step4',
					'step5',
					'lastStep',
				].forEach((key) => {
					postData[key] = state[key].data
					if (postData[key].isComplete && !getters[`${key}/isComplete`]) {
						postData[key].isComplete = false;
					}
				})
				// const userId = process.env.VUE_APP_API_USER
			
				// userId = state.userId
				postData['userId'] = userId
				commit('setLoading', true)
				let isNew = state.new;
				let reqFunc;
				let reqUrl = process.env.VUE_APP_API_URL + `/plan`
				if (isNew) {
					reqFunc = axios.post;
				} else {
					reqFunc = axios.put;
					reqUrl += `/${value.id}`
				}

				try {
					const res = await reqFunc(reqUrl,
						postData, {
						headers: {
							"Authorization": `Bearer ${accessToken}`
						}
					})
					commit(value.data.name + '/setPlan', value.data.data)
					commit('setPlanId', res.data.data.planId)
					commit('setLoading', false)
					commit('setError', {
						data: {
						status: "saved",
						error:	{
							code: "saved",
							message: "Changes updated"
						}
					}})
					if (isNew) {
						router.push('/plan/' + res.data.data.planId + '/overview')
					} else if (value.next) {
						router.push('/plan/' + res.data.data.planId + '/' + value.next);
					} else if (value.submit) {
						router.push('/search');
						
					} else {

						commit("toOverview", res.data.data.planId)
					}
				} catch (e) {

					commit('setLoading', false)
					commit('setError', e.response)
					// if (e.response.status == 400) {
					// 	let { userId } = sessionStorage.getAuth();
					// 	try {
					// 		const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId + '/logout')
					// 		document.location.href = process.env.VUE_APP_WEBSITE_URL+"/logout/";				
					// 	} catch (e) {
							
					// 	}										
					// }
				}
			
			}else {
				commit("setError", "Seems you've logout, Please login to save plans") 
			}


		},

		async duplicatePlan({
			commit,
			
		}, value) {
			// to seperate data and post to server
			const { userId, accessToken } = sessionStorage.getAuth();
			if (userId) {
				try {
					const res = await axios.get(process.env.VUE_APP_API_URL + `/plan/` + value, {
						headers: {
							"Authorization": `Bearer ${accessToken}`
						}
					})
					const data = await res.data
					commit('setDuplicatePlan', data.data)

					router.push('/plan/copy/info')
					// getters["getComplete"];

				} catch (e) {

					commit('setLoading', false)
					commit('setError', e.response)
					// if (e.response.status == 400) {
					// 	let { userId } = sessionStorage.getAuth();
					// 	try {
					// 		const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId + '/logout')
					// 		document.location.href = process.env.VUE_APP_WEBSITE_URL+"/logout/";				
					// 	} catch (e) {
							
					// 	}										
					// }
				}
			}else {
				commit("setError", "Seems you've logout, Please login to save plans") 
			}

		},

	},

	getters: {

		getID: () => {
			let id = '';
			const route = useRoute();
			id = route.params.id;
			return id
		},

		planStatus: (state) => {
			return state.steps.every(step => {
				return step.status
			})
		},

		getRequired: ({nameAndInfo}) => {

			if (nameAndInfo.data.fullName && nameAndInfo.data.fullName != '' && nameAndInfo.data.declaration ) {
				// return state.nameAndInfo.data.fullName 
				return true
			} else {
				return false
				// return state.nameAndInfo.data.kPins.length
			}
		},
	},
	modules: {
		// seprate store
		nameAndInfo,
		whatAndWhy,
		step1,
		step2,
		step3,
		step4,
		step5,
		lastStep,
	},
}