export default {
	namespaced: true,
	state: {
		name: 'step2',
		status: 'draft',
		isComplete: false,

		response: {
			status: 'true/false',
			message: 'lorem ipsum',
		},

		reading: [{
				head: 'Everyone who crosses your orchard boundary has the potential to introduce threats',
				body: `<p>Growers don’t operate in isolation. Everyone who crosses your orchard boundary has the potential to introduce threats. Share knowledge with your workers, contractors, and colleagues and consider ways to overcome cultural or language barriers so that you are communicating effectively.</p>
<p> Consistency of grower messages to contractors keeps everyone on the same page, which is important as all contractors who come on to your orchard must have and operate to a biosecurity plan that addresses awareness of pathway risks and what steps they take to manage them (including reporting and hygiene requirements) before entering your orchard.</p>
<p> Contractors who are part of the Zespri CAV scheme will have a biosecurity plan as part of their scheme accreditation. Information about how to ensure contractors outside of this scheme have and operate to a plan is available on the KVH website at www.kvh.org.nz.</p>`,
				accepted: false,
			},
		],

		data: {
			expectations: {
				content: '',
				applicable: true,
			},
			training: {
				content: '',
				applicable: true,
			},
			communication: {
				content: '',
				applicable: true,
			},
			verify: {
				content: '',
				applicable: true,
			},
			
			isComplete: false
		},
		fallback: {
			expectations: {
				content: '',
				applicable: true,
			},
			training: {
				content: '',
				applicable: true,
			},
			communication: {
				content: '',
				applicable: true,
			},
			verify: {
				content: '',
				applicable: true,
			},
						isComplete: false
		},
		// all the data
	},
	mutations: {
		// change state
		setComplete(state, value) {
			state.data.isComplete = true
		},

		setPlan(state, value) {
			state.data = value
		},
	},
	actions: {
		// async and do mutations
		complete({
			commit,
			state
		}, value) {
			commit('setComplete', value)
		},
	},
	getters: {
		isComplete: (state) => {
			let dataKeys = Object.keys(state.data)
			dataKeys = dataKeys.filter(data => data !== 'isComplete');
			let result = dataKeys.every((dataKey) => {
				let data = state.data[dataKey]
				if (data.applicable) {
					return data.content.length > 0
				} else {
					return true
				}
			})
			state.data.isComplete = result
			return result
		},
	},
}