<template>
  <Layout></Layout>
</template>

<script>
import Layout from './container/Layout.vue'
import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
    Layout,
  },
  // setup() {
  // 	const store = useStore();
  // 	// store.dispatch("customers/gets");
  // },
}
</script>

<style></style>
