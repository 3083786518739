<template lang="">
<div class="btns">
	<a data-bs-toggle="modal" data-bs-target="#edit" class="btn green">edit <i class="far fa-edit"></i></a>
	<a data-bs-toggle="modal" data-bs-target="#duplicate" class="btn green">duplicate <i class="far fa-clone"></i></a>
	<a data-bs-toggle="modal" data-bs-target="#delete" class="btn green">delete <i class="far fa-trash-alt"></i></a>	
  </div>
</template>
<script>
	import { ref, computed } from "vue";
	import { useStore } from "vuex";
	export default {
		setup(props) {
			const store = useStore();
			const editPlan = (id) => {
				// store.dispatch("plans/edit", id);
				store.dispatch("plans/editPlan", id);
			};
			const copyPlan = (data) => {
				store.dispatch("create/duplicatePlan", data);
			};
			const deletePlan = (id) => {
				store.dispatch("plans/deletePlan", id);
			};
			return {
				editPlan,
				copyPlan,
				deletePlan,
			};
		},
		props: ["datas", "id"],
	};
</script>
<style lang="scss">
</style>
