export default {
	namespaced: true,
	state: {
		name: 'lastStep',
		status: 'draft',
		isComplete: false,

		response: {
			status: 'true/false',
			message: 'lorem ipsum',
		},
		reading: [{
			head: 'Reporting is a good thing!',
			body: `
<p>Taking action and reporting an unusual pest or vine symptom is a good thing. The potential benefit of this action to our industry cannot be overestimated, but what happens next and how will your operation be affected? There can be implications for biosecurity champions that do the right thing and make reports, but it’s worth it because the earlier something is reported the greater chance we have of eradicating it. </p>
<p>There is a process for what happens next (it’s generic and each response may differ slightly) and how you can get more information.
</p>`,
			accepted: false,
		}],

		data: {identification: {
				applicable: false,
			},
			effect: {
				content: '',
				applicable: true,
			},
			talkTo: {
				content: '',
				applicable: true,
			},
			isComplete: false},
		fallback: {
			identification: {
				applicable: false,
			},
			effect: {
				content: '',
				applicable: true,
			},
			talkTo: {
				content: '',
				applicable: true,
			},
			isComplete: false

		},
		// all the data
	},
	mutations: {
		// change state
		setComplete(state, value) {
			state.data.isComplete = true
		},

		setPlan(state, value) {
			state.data = value
		},
	},
	actions: {
		// async and do mutations
		complete({
			commit,
			state
		}, value) {
			commit('setComplete', value)
		},
	},
	getters: {
		isComplete: (state) => {
			if (!state.data) {
				return false
			}
			if (state.data.identification.applicable != false) {
				if (state.data.effect.applicable != true &&
					state.data.effect.content == '') {
					
					if (state.data.talkTo.applicable != true &&
						state.data.talkTo.content == '') {
							return true						
					}
					else if (state.data.talkTo.applicable == true &&
						state.data.talkTo.content != ''){
						return true
					} else {
						return false
					}					
				}
				else if (state.data.effect.applicable == true &&
					state.data.effect.content != ''){
					if (state.data.talkTo.applicable != true &&
						state.data.talkTo.content == '') {
							return true						
					}
					else if (state.data.talkTo.applicable == true &&
						state.data.talkTo.content != ''){
						return true
					} else {
						return false
					}		
				} else {
					return false
				}
			}
			else {
				return false
			}
			// let dataKeys = Object.keys(state.data)
			// let result = dataKeys.every((dataKey) => {
			// 	let data = state.data[dataKey]
			// 	if (data.applicable) {
			// 		return data.content.length > 0
			// 	} else {
			// 		return true
			// 	}
			// })
			// state.data.isComplete = result
			// return result
		},
	},
}