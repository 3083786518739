<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>

					<p>
						Read through the readings below, then proceed down to
						the bottom of the page and answer the questions to
						complete this section.
					</p>
					<p>
						Every KPIN must have a completed On-Orchard Biosecurity
						Plan.
					</p>
				</div>

				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#reading"> Reading</router-link>
							<ul>
								<li
									v-for="(item, index) in reading"
									:key="index"
								>
									<router-link :to="'#reading' + index"
										>{{ item.head }}
									</router-link>
								</li>
							</ul>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.offshore.applicable == true &&
											data.offshore.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#one"
										>Offshore risks</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.local.applicable == true &&
											data.local.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#two"
										>Local risks</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.border.applicable == true &&
											data.border.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#three"
										>Orchard border</router-link
									>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="step1">
						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-2"
							name="step1"
							class="large"
						></Buttons>
						<hr />
						<h2 id="reading">Reading</h2>
						<div class="accordion">
							<div
								v-for="(item, index) in reading"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2
									class="accordion-header"
									:id="'reading' + index"
								>
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<h2 id="questions">Questions</h2>
						<p>
							Below are three questions on the types of risk your
							orchard faces. Offshore risk, Local risk, and
							Orchard border risks. Read the sample actions and
							considerations which may help to guide you on how
							you would approach dealing with these risks, and
							then enter your plan in the text box below.
						</p>
						<div class="block"></div>

						<Question
							boxTitle="Risk type"
							idTitle="one"
							subTitle="Offshore"
							radioAcceptance="What I am doing to understand offshore
										risk:"
							:datas="data.offshore"
						>
							<p>
								Keep informed about biosecurity threats to the
								kiwifruit industry and how they spread:
							</p>
							<ul class="read">
								<li>Read - KVH Bulletin and website</li>
								<li>
									Talk to people - KVH staff, regional
									coordinators and biosecurity champions,
									post-harvest
								</li>
								<li>
									Listen - industry roadshows, field days and
									webinars
								</li>
							</ul>
							<p>
								Is there any additional information that changes
								how I operate?
							</p>
						</Question>

						<Question
							boxTitle="Risk type"
							idTitle="two"
							subTitle="My local region"
							radioAcceptance="What I am doing to understand local
										risk:"
							:datas="data.local"
						>
							<p>
								Keep informed about what is happening in your
								region:
							</p>
							<ul class="read">
								<li>
									Read - know your growing region and status.
									Understand and follow relevant controls
									which are designed to protect you
								</li>
								<li>
									Talk to people - create a network of people
									you trust and check-in with them
								</li>
								<li>
									Listen - are any orchards that you are
									closely connected to experiencing pest or
									pathogen problems?
								</li>
							</ul>
							<p>
								Is there any additional information that changes
								how I operate?
							</p>
						</Question>

						<Question
							boxTitle="Risk type"
							idTitle="three"
							subTitle="Orchard border"
							radioAcceptance="What are the ways in which biosecurity
										threats may enter my orchard? Write them
										down in order of likelihood:"
							:datas="data.border"
						>
							<p>
								If a new biosecurity threat entered New Zealand
								or my growing region, how could it enter my
								orchard? What can I do to reduce the risk?
							</p>
							<p>
								Do I understand all the potential pathways and
								am I managing the risks associated with these?
								This will include:
							</p>
							<ul class="read">
								<li>vehicles</li>
								<li>plant material</li>
								<li>people</li>
								<li>
									imported goods that may harbour pests or
									soil e.g. vehicles, online purchases,
									construction materials etc
								</li>
								<li>
									vectors of pests/diseases e.g. bees,
									insects, soil, imported fruit, other plant
									species
								</li>
								<li>
									associations with orchards in other regions
								</li>
								<li>
									anything stored on the orchard, leased
									premises, bins etc
								</li>
							</ul>
						</Question>
						<br />
						<Buttons
							:datas="datas"
							:status="isComplete"
							:id="plan.data.planId"
							next="step-2"
							name="step1"
						></Buttons>
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, computed, onMounted } from "vue";

	import Buttons from "../../components/Buttons.vue";
	import PageNav from "../../components/PageNav.vue";
	import Layout from "./planContainer.vue";

	import Question from "../../components/Question.vue";

	export default {
		setup() {
			const store = useStore();

			// const status = computed(() => store.state.create.step1.status);

			const data = computed(() => store.state.create.step1.data);
			const reading = computed(() => store.state.create.step1.reading);
			const datas = computed(() => store.state.create.step1);
			const plan = computed(() => store.state.create.plan);

			const isComplete = computed(
				() => store.getters["create/step1/isComplete"]
			);

			const state = reactive({
				name: "Step 1: understand your risks",
			});

			onMounted(() => {
				store.dispatch("create/getsPlan", "whatAndWhy");
			});
			return {
				state,
				status,
				reading,
				data,
				isComplete,
				datas,
				plan,
			};
		},
		components: {
			Buttons,
			PageNav,
			Question,
			Layout,
		},
	};
</script>

<style lang="scss">
	.form-div {
		margin-bottom: 3rem;
		ul > li {
			list-style: none;
			padding-left: 1rem;
			margin-bottom: 0.75rem;
		}
	}
</style>
