export default {
	namespaced: true,
	state: {
		name: 'step3',
		status: 'draft',
		isComplete: false,

		response: {
			status: 'true/false',
			message: 'lorem ipsum',
		},

		reading: [{
				head: 'The movement of plant material',
				body: `<p>The movement of plant material is considered the highest risk pathway of introducing pests or diseases into your orchard. Infection may not be immediately obvious on arrival.</p>
<p> You can reduce risk associated with plant material by following any movement controls in place, inspecting all material on arrival, and isolating it for a quarantine period so that you limit the risk of exposing the entire orchard to new pests and diseases.</p>
<p> Keep records so that if we are faced with an incursion, we can quickly trace plant material movements, increase our chances of successful eradication, and limit impacts to the industry.</p>`,
				accepted: false,
			}
		],

		data: {
			rootstock: {
				content: '',
				applicable: true,
				tracing: false,
			},
			pollen: {
				content: '',
				applicable: true,
				tracing: true,
			},
			fertilizers: {
				content: '',
				applicable: true,
				tracing: false,
			},
			other: {
				content: '',
				applicable: true,
				tracing: false,
			},
			isComplete: false

		},
		fallback: {
			rootstock: {
				content: '',
				applicable: true,
				tracing: false,
			},
			pollen: {
				content: '',
				applicable: true,
				tracing: true,
			},
			fertilizers: {
				content: '',
				applicable: true,
				tracing: false,
			},
			other: {
				content: '',
				applicable: true,
				tracing: false,
			},			isComplete: false

		},
		// all the data
	},
	mutations: {
		// change state
		setComplete(state, value) {
			state.data.isComplete = true
		},

		setPlan(state, value) {
			state.data = value
		},
	},
	actions: {
		// async and do mutations
		complete({
			commit,
			state
		}, value) {
			commit('setComplete', value)
		},
	},
	getters: {
		isComplete: (state) => {
			let dataKeys = Object.keys(state.data)				
			dataKeys = dataKeys.filter(data => data !== 'isComplete');
			let result = dataKeys.every((dataKey) => {
				let data = state.data[dataKey]
				if (data.applicable) {
					return data.content.length > 0
				} else {
					return true
				}
			})
			state.data.isComplete = result
			return result
		},
	},
}