<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>
					<p>
						As you work your way through the information in this
						Biosecurity Plan Helper you will find out more about
						biosecurity, the importance of on-orchard biosecurity to
						the kiwifruit industry, and what you can do to improve
						biosecurity on the property you are responsible for
						and/or work on.
					</p>
					<p>
						Every orchard must have a completed On-Orchard
						Biosecurity Plan.
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction {{ state.pin }}</router-link
							>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.fullName &&
											data.kPins.length &&
											data.declaration
										"
										class="tick accepted"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#nameAndInfo"
										>Name & Info</router-link
									>
								</li>
							</ul>
						</li>
					</ul>

					<br />
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="nameAndInfo">
						<Buttons
							class="large"
							:id="plan.data.planId"
							:datas="datas"
							:status="isComplete"
							next="what-and-why"
							name="nameAndInfo"
						></Buttons>
						<hr />
						<h2>Questions</h2>
						<p>
							Start your plan by entering your name, related
							KPINs, PMO number (optional) and any notes you may
							have to remind yourself about this plan in the
							future (optional).
						</p>
						<h3 class="form-title">{{ state.name }}</h3>
						<div class="form-div">
							<div class="row">
								<p>
									Your name, as the person completing this
									On-Orchard Biosecurity Plan:
								</p>
								<div class="form-floating">
									<input
										type="text"
										class="form-control"
										id="inputName"
										placeholder="Name"
										required
										v-model="data.fullName"
									/>
									<label for="inputName">Full Name</label>
									<span class="flag">Required</span>
								</div>
							</div>

							<div class="row">
								<p>
									If you are completing this On-Orchard
									Biosecurity Plan at an MSO level, please
									name the Entity or list the PMO number -
									optional
								</p>
								<div class="form-floating">
									<input
										type="text"
										class="form-control"
										id="inputPMO"
										placeholder="Entity / PMO number"
										v-model="data.pmoNumber"
									/>
									<label for="inputEmail"
										>Entity / PMO number</label
									>
								</div>
							</div>

							<div class="row">
								<p>
									Add the KPIN you are completing this
									On-Orchard Biosecurity Plan for, and any
									other KPIN this On-Orchard Biosecurity Plan
									applies to:
									<br />
									<b v-if="datas.errorKpin">
										{{ datas.errorKpin }}</b
									>
								</p>
								<div class="row">
									<div class="col-lg-8">
										<div class="form-floating">
											<input
												type="number"
												class="form-control"
												id="inputKpin"
												placeholder="Kpin"
												@keyup="oldKpin"
												required
												v-model="state.kpin"
											/>
											<label for="inputPhone">
												Enter KPIN number and press
												Add</label
											>
										</div>
									</div>
									<div class="col-lg-4">
										<a class="btn" @click="addKpins">
											Add
										</a>
									</div>
									<div class="kpins">
										<Kpins :list="data"></Kpins>
									</div>
								</div>
							</div>
							<div class="row">
								<p>
									Reminder notes for yourself about this plan
									- optional
								</p>
								<div class="form-floating">
									<Textarea
										v-model="data.reminder"
										:value="data.reminder"
										:id="'inputreminder'"
										placeHolder="Reminder"
										required="true"
										applicable="false"
									>
									</Textarea>
									<label for="inputPhone"
										>Reminder for Yourself</label
									>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<h3>Declaration</h3>
									<p>
										I am the person responsible for
										completing the following On-Orchard
										Biosecurity Plan for each KPIN that I
										have listed. I understand the
										information that I have provided about
										actions taken to manage biosecurity risk
										and consider it to be true and correct.
									</p>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											value=""
											id="agree"
											v-model="data.declaration"
										/>
										<label
											class="form-check-label"
											for="agree"
										>
											I agree that the above declaration
											is accurate
										</label>
									</div>
								</div>
							</div>
							<br />
						</div>
						<br />

						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="what-and-why"
							name="nameAndInfo"
						></Buttons>
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { ref, reactive, computed, onMounted } from "vue";
	import { useStore } from "vuex";
	import { useRoute, onBeforeRouteLeave } from "vue-router";

	import Layout from "./Plans/planContainer.vue";
	import Buttons from "../components/Buttons.vue";
	import Kpins from "../components/Kpins.vue";
	import PageNav from "../components/PageNav.vue";
	import Textarea from "../components/textarea.vue";

	export default {
		setup(props) {
			const store = useStore();

			const main = computed(() => store.state.create);
			const data = computed(() => store.state.create.nameAndInfo.data);
			const datas = computed(() => store.state.create.nameAndInfo);
			const plan = computed(() => store.state.create.plan);

			const isComplete = computed(
				() => store.getters["create/nameAndInfo/isComplete"]
			);
			const required = computed(() => store.getters["create/getRequired"]);
			// const btnStat = reactive("incomplete");

			const oldKpin = () => {
				store.dispatch("create/nameAndInfo/oldKpin");
			};

			const addKpins = () => {
				store.dispatch("create/nameAndInfo/addKpins", state.kpin);
				state.kpin = "";
			};

			const route = useRoute();
			const id = route.params.id;

			const saveStatus = computed(() => {
				if (data) {
					return data;
				} else {
					return "else";
				}
			});

			const state = reactive({
				name: "Name & Info",
				kpin: "",
				p: "",
			});

			// if (isComplete) {
			// 	onBeforeRouteLeave((to, from, next) => {
			// 		let fill = confirm(
			// 			"Do you really want to leave? you havent saved  changes!"
			// 		);
			// 		if (fill == true) {
			// 			next();
			// 		}
			// 	});
			// }

			onMounted(() => {
				if (id == undefined || id === "new") {
					store.dispatch("create/newPlan");
				} else if (id == "copy") {
					store.dispatch("create/copyPlan");
				} else {
					store.dispatch("create/getsPlan");
				}
			});
			return {
				state,
				status,
				data,
				datas,
				isComplete,
				addKpins,
				saveStatus,
				oldKpin,
				plan,
				main,
			};
		},
		components: {
			Buttons,
			Kpins,
			PageNav,
			Textarea,
			Layout,
		},
	};
</script>

<style lang="scss">
</style>
