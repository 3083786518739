<template lang="">
	<Layout>
		<div><h1>404 NotFound</h1></div>
		<br />
		<p>
			The page you are looking for doesnot exist. Please use menu on left
			to navigate to different page
		</p>
	</Layout>
</template>
<script>
import Layout from "./mainContainer.vue";
export default {
	components: { Layout },
};
</script>
<style lang="scss" scoped>
h1 {
	font-size: 66px;
}
</style>
