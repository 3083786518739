<template lang="">
  <ul class="kpin">
    <li v-for="(pin, index) in list.kPins" :key="pin">
      <span class="badge rounded-pill"
        > {{pin}}<i @click="removeKpin(index)" class="fas fa-times"></i>
      </span>
    </li>
  </ul>
</template>
<script>
	import { useStore } from "vuex";
	import { computed } from "vue";
	export default {
		setup() {
			const store = useStore();
			const removeKpin = (index) => {
				store.dispatch("create/nameAndInfo/removeKpins", index);
			};
			return {
				removeKpin,
			};
		},
		props: ["list", "datas"],
	};
</script>
<style lang="scss">
	@import "../assets/scss/mixins.scss";
	ul.kpin {
		padding-left: 0;

		li {
			list-style: none;
			display: inline-block;
			margin-right: 0.375rem;
			padding-left: 0;
			position: relative;

			.badge.rounded-pill {
				padding: 0.5 rem 1 rem;
				font-size: 16px;
				background: $dark-green;
			}

			.fas {
				position: absolute;
				background: red;
				padding: 2px 3px;
				border-radius: 10px;
				font-size: 10px;
				top: -6px;
				right: -6px;
				color: #fff;
				cursor: pointer;
			}
		}
	}
</style>
