export default {
	namespaced: true,
	state: {
		name: 'step5',
		status: 'draft',
		isComplete: false,

		response: {
			status: 'true/false',
			message: 'lorem ipsum',
		},

		reading: [{
				head: 'Anything unusual should be reported immediately',
			body: `
<p>If not detected early, chances of eradication or effective control of a pest or disease is severely reduced. Anything unusual should be reported immediately so we are able to minimise the impacts on people’s orchards, businesses, and livelihoods. </p>
<p>Records provide validation that an activity has occurred. In an incursion, the ability to trace backwards and forwards from a property makes it much easier to identify the extent of the problem.
</p>`,
				accepted: false,
			}
		],

		data: {
			catch: {
				content: '',
				applicable: true,
			},
			report: {
				content: '',
				applicable: true,
			},
			record: {
				content: '',
				applicable: true,
			},
			isComplete: false
		},
		fallback: {
			catch: {
				content: '',
				applicable: true,
			},
			report: {
				content: '',
				applicable: true,
			},
			record: {
				content: '',
				applicable: true,
			},
			isComplete: false
		},
		// all the data
	},
	mutations: {
		// change state
		setComplete(state, value) {
			state.data.isComplete = true
		},

		setPlan(state, value) {
			state.data = value
		},
	},
	actions: {
		// async and do mutations
		complete({
			commit,
			state
		}, value) {
			commit('setComplete', value)
		},
	},
	getters: {
		isComplete: (state) => {
			let dataKeys = Object.keys(state.data)
			dataKeys = dataKeys.filter(data => data !== 'isComplete');
			let result = dataKeys.every((dataKey) => {
				let data = state.data[dataKey]
				if (data.applicable) {

					return data.content.length > 0
				} else {
					return true
				}
			})
			state.data.isComplete = result
			return result
		},
	},
}