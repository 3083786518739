<template lang="">
<div class="btns">
	<a class="btn" @click="save(id)" :class="{ disabled: !required }"> Save </a>
	<a class="btn yellow" @click="complete(id)" :class="{ disabled: !planStatus }">
      Submit Plan
    </a>
	<div @click="toastStatus" class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
		<div id="liveToast" class="toast" :class="{show: state.showToast}" aria-live="assertive" aria-atomic="true">
			<div class="toast-header">
			<strong class="me-auto">{{ res.status ? res.status : "error" }}!</strong>
			<!-- <strong class="me-auto">{{ res.status }}!</strong> -->
				<button type="button" class="btn" aria-label="Close">X</button>
			</div>
			<div class="toast-body">{{res.error.message ? res.error.message : ''}}</div>
		</div>
	</div>
	<div class="floating">
		<a class="btn" @click="save(id)" :class="{ disabled: !required }"> Save </a>
		<a class="btn yellow" @click="complete(id)" :class="{ disabled: !planStatus }">
			Submit Plan
		</a>
	</div>
</div>
</template>
<script>
	import { useStore } from "vuex";
	import { computed, reactive } from "vue";
	import { useRouter, useRoute } from "vue-router";

	export default {
		setup(props) {
			const store = useStore();
			const route = useRoute();
			const state = reactive({
				showComplete: false,
				showToast: false,
				// main: {}
			});
			const main = computed(() => store.state.create.response.isLoading);
			const ad = computed(() => store.state.create);

			const planStatus = computed(() => store.getters["create/planStatus"]);
			const required = computed(() => store.getters["create/getRequired"]);

			const toastStatus = () => {
				state.showToast = !state.showToast;
			};
			const res = computed(() => store.state.create.response.data.data);
			const complete = (id) => {
				store.dispatch("create/submitPlan", {
					data: props.datas,
					id: id,
				});
			};

			const save = (id) => {
				if (route.query.new) {
					store.dispatch("create/createPlan", props);
				} else {
					store.dispatch("create/savePlan", {
						data: props.datas,
						id: id,
					});
				}
				state.showToast = true;
			};

			return {
				// statusBtn,
				toastStatus,
				state,
				complete,
				save,
				required,
				planStatus,
				main,
				res,
				ad,
			};
		},
		props: ["datas", "name", "next", "id"],
	};
</script>
<style lang="scss">
	@import "../assets/scss/mixins.scss";
	.btns {
		.btn {
			margin-right: 15px;
		}
		@include mobile {
			.btn {
				width: 100%;
				margin-right: 0;
				margin-bottom: 15px;
			}
		}
		&.large {
			@include mobile {
				display: none;
			}
		}
		.floating {
			position: fixed;
			display: none;
			bottom: 0;
			right: 20px;
			.btn {
				width: auto;
				border-radius: 35px;
			}
			@include mobile {
				display: block;
			}
		}
		.toast-header {
			.btn {
				padding: 0 10px;
				text-transform: lowercase;
			}
		}
	}
</style>
