import axios from 'axios'
export default {
	namespaced: true,
	state: {
		name: 'whatAndWhy',
		status: 'draft',
		response: {
			status: 'true/false',
			message: 'lorem ipsum',
		},
		options: [{
				head: 'What is biosecurity?',
				body: `<p>Biosecurity is about protecting New Zealand from the risks posed by unwanted pests and diseases. It means managing risk to prevent the introduction of unwanted organisms, preventing their spread if they do arrive, and always maintaining vigilance so they can be detected.</p> <p>All 5 million of us are part our biosecurity system, protecting New Zealanders, our health and way of life, our natural and productive resources, and our biodiversity.</p> <p>To kiwifruit growers, biosecurity specifically includes the actions, practices and rules that are designed to keep out the pests and diseases that could affect kiwifruit or kiwifruit vines at a national, regional, or individual orchard level.</p><p>Biosecurity threats could affect Orchard Gate Return (OGR), jobs and community. The next big threat could be here, undetected and spreading. It might already be on your doorstop. You have the power to protect your livelihood and investment with the five easy interventions covered in this on-orchard biosecurity plan.</p><p>The following information is designed to provide guidance to help you identify biosecurity risks, and how to address them. By completing an on-orchard biosecurity plan you will be identifying and prioritising biosecurity practices relevant to your orchard and property. The plan you develop will be unique to your orchard, staff, and surrounding environment.</p>`,
				alias: 'whatIsBioSec',
			},
			{
				head: 'Why is on-orchard biosecurity so important?',
				body: `<p>By practicing better biosecurity on-orchard, growers can reduce or eliminate the impacts of pests and diseases that are established in New Zealand, whether they have already reached the property or not.</p><h4>Impacts of unwanted pests and diseases can include:</h4> <ul>
	<li>Reduction in productivity</li>
	<li>Reduction in the quality and value of kiwifruit</li>
	<li>Lower market value of kiwifruit</li>
	<li>The cost of control – it often costs more time and money to control an unwanted organism than to prevent it.</li>
</ul>
<p>Better biosecurity on kiwifruit orchards also improves New Zealand’s national resilience to outbreaks of disease. We know that the overall size of any outbreak may be reduced in an environment where good biosecurity practices form part of everyday practice, rather than being introduced after an outbreak has started.</p>
<p>Where biosecurity measures are in place that limit the spread of established diseases they also limit the silent spread of a disease (for example, the soil-borne <i>Ceratocystis fimbriata</i>) before it is detectable.</p>`,
				alias: 'whyIsBioSec',
			},
			{
				head: 'How do pests and diseases spread within and between orchards?',
				body: `<p>There are a range of pathways that pests and disease-causing pathogens can use to spread between orchards and the risk depends on the organism. Some of the common pathways include:</p>
<h4>Plant material (rootstock, budwood and pollen): </h4>
<p>Plant material presents the highest risk of spreading a wide range of pests and pathogens over long distances.</p>
<h4>Tools: </h4>
<p>Tools used on infected material can easily spread this to other plants within and between orchards.</p>
<h4>Soil on footwear or equipment: </h4>
<p>Soil-borne pathogens such as <i>Ceratocystis fimbriata</i> can be present in even the smallest amounts of soil (1g or less) which can be present on shoes or unclean equipment.</p>
<h4>Windborne: </h4>
<p>Perhaps the most difficult to control are windborne pests that can fly or be spread by wind.</p>`,
				alias: 'spread',
			},
			{
				head: 'What can you do to improve biosecurity on your property?',
				body: `
		  	<p>A wide variety of interventions can be applied to improve on-orchard biosecurity. Growers need to discuss this with staff and contractors and complete a written biosecurity plan for the orchard. The following general biosecurity guidance will apply to most growers.</p>
<h4>1. Understand your risks</h4>
<p>What pests and diseases could affect my orchard?</p>
<p>What is happening in my local area?</p>
<p>How might these enter my orchard? (Who and what enters my orchard and what might bring these in?)</p>
<h4>2. Agree what must happen on site</h4>
<p>Share knowledge with staff and contractors.</p>
<p>Agree requirements and ensure that they are met.</p>
<h4>3. Source and trace clean plant material</h4>
<p>Rootstock, budwood, pollen, shelter and compost.</p>
<p>Kiwifruit Plant Certification Scheme (KPCS) certification is mandatory for sourcing rootstock.</p>
<h4>4. Check and clean</h4>
<p>Consider the risk from tools, vehicles and machinery, harvest bins, people and clothing.</p>
<p>Ensure everything coming across your boundary is free from soil and plant material.</p>
<p>Clean tools at least between rows.</p>
<h4>5. Report the unusual</h4>
<p>Be on the lookout and if you find anything unusual, catch it, snap it, and report it. You can report online using the free Find-A-Pest app, by phoning KVH on 0800 665 825 or contacting the Biosecurity New Zealand hotline on 0800 80 99 66.</p>
		
		`,
				alias: 'improve',
			},
			{
				head: 'What does a 5 million biosecurity team look like?',
				body: `<h4>Everyone can:</h4>
<ul>
<li>Take a photo of any unusual bug or disease symptom in the orchard or environment and report it to KVH on 0800 665 825 or Biosecurity New Zealand on 0800 80 99 66. Bug photos can also be reported online using the free Find-A-Pest app.</li>
<li>Thoroughly check and clean all vehicles, machinery, and tools before moving them to another property or orchard.</li>
<li>Routinely unpack online purchases carefully in case any hitchhiker pests are inside.</li>
<li>Promote New Zealand’s biosecurity rules to overseas visitors before they come to visit.</li></ul>
<h4>At your place of work, you can:</h4><ul>
<li>Build biosecurity requirements into contracts.</li>
<li>Establish a pest of the month campaign to educate staff about potential risks and what to do if anything of concern is found.</li>
<li>Include biosecurity as a standard item on meeting and board agendas.</li>
<li>Get staff training to manage biosecurity risks encountered on the job.</li></ul>`,
				alias: 'team',
			},
		],

		data: {
			whatIsBioSec: false,
			whyIsBioSec: false,
			spread: false,
			improve: false,
			team: false,
			isComplete: false
		},
		fallback: {
			whatIsBioSec: false,
			whyIsBioSec: false,
			spread: false,
			improve: false,
			team: false,
			isComplete: false
		},
		// all the data
	},
	mutations: {
		// change state
		setComplete(state) {
			state.isComplete = !state.isComplete
		},
		setPlan(state, value) {
			state.data = value
		},
		setError(state, e) {
			state.response.message = e
			state.response.status = 'error'
		},
	},
	actions: {},
	getters: {
		isComplete: (state) => {

			let dataKeys = Object.keys(state.data)
			dataKeys = dataKeys.filter(data => data !== 'isComplete');
			let result = dataKeys.every((dataKey) => state.data[dataKey])
			state.data.isComplete = result
			return result
		},
	},
}