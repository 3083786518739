<template lang="">
  <div>
    <div class="form-title" :id="idTitle">
      <h3>{{ boxTitle }}</h3>
      <p>{{ subTitle }}</p>
    </div>
    <div class="form-div">
      <div class="row">
		  <h3>Example actions and considerations you could employ to reduce your risk:</h3>
        
        <slot></slot>
      </div>
      <div class="row">
        <h3>Actions I have taken to protect my investment:</h3>
        <div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              :name="'check-' + idTitle"
              :id="'check-' + idTitle"
              :checked="datas.applicable"
              v-model="datas.applicable"
              :value="true"
            />
            <label class="form-check-label" :for="'check-' + idTitle">
              {{radioAcceptance}}
            </label>
          </div>
        </div>
        <div class="form-floating">
			
		
			<Textarea
				v-model="datas.content"
				:value="datas.content"
				:id="'inputName'"
				placeHolder="Name"
				:applicable="datas.applicable"
			>
			</Textarea>
          <label for="inputName">Actions</label> <span class="flag">required </span>
        </div>
		<div>
		<div class="form-check">
			<input
				class="form-check-input"
				type="checkbox"
				value=""
				:name="'tracing-' + idTitle + '-3'"
              	:id="'tracing-' + idTitle + '-3'"
				v-model="datas.tracing"
			/>
			<label
				class="form-check-label"
				:for="'tracing-' + idTitle + '-3'"
			>
				I have updated my tracing records (tick when completed)
			</label>
		</div>
		</div>
		<br/>
		<br/>
        <div>
          <div class="form-check">
			  
            <input
              class="form-check-input"
              type="radio"
              :name="'check-' + idTitle + '-2'"
              :id="'check-' + idTitle + '-2'"
              :checked="!datas.applicable"
              v-model="datas.applicable"
              :value="false"
            />

			

            <label class="form-check-label" :for="'check-' + idTitle + '-2'">
              This risk is not applicable to my KPIN
            </label>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
	import Textarea from "./textarea.vue";
	import { onBeforeRouteLeave } from "vue-router";
	export default {
		setup(props) {
			// onBeforeRouteLeave((to, from, next) => {
			// 	let answer;
			// 	if (props.datas.content) {
			// 		next(false);
			// 		answer = window.confirm(
			// 			"Do you really want to leave? you have unsaved changes!"
			// 		);
			// 	} else {
			// 		next();
			// 	}
			// });
			// return {};
		},
		props: ["idTitle", "boxTitle", "subTitle", "radioAcceptance", "datas"],
		components: { Textarea },
	};
</script>
<style lang="scss"></style>
