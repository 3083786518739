import {
	createLogger,
	createStore
} from 'vuex'
import create from './modules/create'
import plans from './modules/plans'
import axios from 'axios'
import {
	useRoute
} from 'vue-router';

export default createStore({
	// plugins: [createLogger()],
	state: {
		isActive: false,
		isSubmenu: false,
		isAdmin: false,
		userId: "",
		authToken: "",
		accessToken: "",
		user: {},
		response: {},
	},
	mutations: {
		setNavToggle(state, value) {
			state.isActive = !state.isActive
		},

		setSubmenu(state, value) {
			state.isSubmenu = !state.isSubmenu
		},
		setUser(state, value) {
			
			state.userId = value.userId
			state.authToken = value.authToken
			if (value.data == "noauth") {
				
			}
			state.accessToken = value.accessToken
		},

		setTheUser(state, value) {
			state.user = value.user
		},

		

		isAdmin(state, value) {
			state.isAdmin = value
		},

		setError(state, e) {
			state.response.message = e
			state.response.status = 'error'
		},
		setLoading(state, value) {
			state.response.isLoading = value
		},
	},
	actions: {
		// async and do mutations

		navToggle({
			commit,
			state
		}, value) {
			commit('setNavToggle', value)
		},

		showSubmenu({
			commit,
			state
		}, value) {
			commit('setSubmenu', value)
		},
		async logout() {
			let { userId } = sessionStorage.getAuth();
			try {
				const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId + '/logout')
				document.location.href = process.env.VUE_APP_WEBSITE_URL+"/logout/";				
			} catch (e) {
			}

			
		},

		async getUser({
			commit,
			dispatch,
			state
		}) {
			// state.response.isLoading = true
			
	

			const route = useRoute();

			let userId = route.params.userId;
			let authToken = route.params.auth_token;
			let decoded = decodeURI(authToken);


			if (userId != "" && decoded != "") {
				sessionStorage.setItem("userId", userId)
				sessionStorage.setItem("accessToken", "will be updated to invalid?")
				sessionStorage.setItem("authToken", decoded)
				

				commit('setLoading', true)
				commit('setUser', {
					userId: userId,
					decoded: decoded,
					data: "noauth"
				})
				

				try {
					const res = await axios.post(process.env.VUE_APP_API_URL + `/user/${userId}/accessToken`, null, {
						headers: {
							"Authorization": `Bearer ${decoded}`
						}

					})
					const data = await res.data
					
					sessionStorage.setItem("accessToken", data.data.access_token);
					commit('setUser', {
						userId: userId,
						authToken: decoded,
						accessToken:  data.access_token
					})

					
				} catch (e) {
					commit('setLoading', false)
					sessionStorage.setItem("accessToken", "invalid Token");
					setTimeout(() => {
						// dispatch("getUser", value )

					}, 1000)
					commit('setError', e)
				}

				commit('setLoading', false)
			} else {				
				let { userId, authToken, accessToken } = sessionStorage.getAuth();

				if (accessToken != "" || state.accessToken != "") {
					commit('setUser', {
						userId: userId,
						authToken: decoded,
						accessToken: accessToken
					})
				}

				else {
					// dispatch("getUser")
					document.location.href = process.env.VUE_APP_LOGIN_URL;
				}
			}
		},

		async getTheAdmin({
			commit,
			state
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)

			const { userId, accessToken } = sessionStorage.getAuth();
			try {
				const resp = await axios.get(process.env.VUE_APP_API_URL + `/user/${userId}/isAdmin`, {
					headers: {
						"Authorization": `Bearer ${accessToken}`
					}

				})
				const data = await resp.data.data				
				sessionStorage.setItem("isAdmin", data.isAdmin);
				commit('isAdmin', data.isAdmin)
			} catch (e) {
				commit('setLoading', false)
				sessionStorage.setItem("isAdmin", false);
				setTimeout(() => {
					// dispatch("getUser", value )

				}, 1000)
				commit('setError', e)
			}

			commit('setLoading', false)
		},


		async getTheUser({
			commit,
			state
		}, value) {
			// state.response.isLoading = true
			commit('setLoading', true)

			const { userId, accessToken } = sessionStorage.getAuth();
			try {
				const res = await axios.get(process.env.VUE_APP_API_URL + `/user/` + userId , {
					headers: {
						"Authorization": `Bearer ${accessToken}`
					}
				})
				const data = await res.data.data
				commit('setTheUser', data)
			} catch (e) {
				commit('setLoading', false)
				commit('setError', e)
			}

			commit('setLoading', false)
		},

	},
	getters: {
	},
	modules: {
		// seprate store
		create,
		plans,
	},
})