import {
	createWebHistory,
	createWebHashHistory,
	createRouter
} from "vue-router";
import Dashboard from "@/container/Dashboard";
import StoreUser from "@/container/storeUser";
import Logout from "@/container/Logout";
import Create from "@/container/Create";
import After from "@/container/After";
import Plans from "@/container/Plans";
import Search from "@/container/Search";
import Overview from "@/container/Plans/Overview"
import WhatAndWhy from "@/container/Plans/WhatAndWhy"
import StepOne from "@/container/Plans/StepOne"
import StepTwo from "@/container/Plans/StepTwo"
import StepThree from "@/container/Plans/StepThree"
import StepFour from "@/container/Plans/StepFour"
import StepFive from "@/container/Plans/StepFive"
import LastStep from "@/container/Plans/LastStep"
import NotFound from "@/container/NotFound"

const routes = [
	{
		path: "/",
		name: "login",
		component: () => {
			document.location.href = process.env.VUE_APP_LOGIN_URL;
		}
	},{
		path: "/user/:userId/login/:auth_token",
		name: "loader",
		component: StoreUser,
	},
	{
		path: '/dashboard',
		name: "dashboard",
		component: Dashboard
	},	
	{
		path: "/search",
		name: "search",
		component: Search,
	},
	{
		path: "/after",
		name: "after",
		component: After,
	},

	//   PLANS

	{
		path: "/plans",
		name: "plans",
		component: Plans,
	},
	{
		path: "/:userId/plans",
		name: "userPlans",

		component: Plans,
	},

	{
		path: "/plan",
		name: "plan",
		component: Create
		// component: () => {
		// 	// let newPlanId =  Math.round(Math.random() * 10000);
		// 	router.push("/plan/new/info");
		//  }
		
	},

	{
		path: "/plan/:id/info",
		name: "create",
		component: Create,
	},
	{
		path: "/plan/:id/",
		name: "Overview",
		component: Overview
	},
	{
		path: "/plan/:id/overview",
		name: "Overview",
		component: Overview
	},
	{
		path: "/plan/:id/info",
		name: "nameAndInfo",
		component: Create
	},
	{
		path: "/plan/:id/what-and-why",
		name: "whatAndWhy",
		component: WhatAndWhy
	},
	{
		path: "/plan/:id/step-1",
		name: "step1",
		component: StepOne
	},
	{
		path: "/plan/:id/step-2",
		name: "step2",
		component: StepTwo
	},
	{
		path: "/plan/:id/step-3",
		name: "step3",
		component: StepThree
	},
	{
		path: "/plan/:id/step-4",
		name: "step4",
		component: StepFour
	},
	{
		path: "/plan/:id/step-5",
		name: "step5",
		component: StepFive
	},
	{
		path: "/plan/:id/last-step",
		name: "lastStep",
		component: LastStep
	},
	//
	{
		path: "/:userId/plan/:id/info",
		name: "usercreate",
		component: Create,
	},
	{
		path: "/:userId/plan/:id/",
		name: "userOverview",
		component: Overview
	},
	{
		path: "/:userId/plan/:id/overview",
		name: "userOverview",
		component: Overview
	},
	{
		path: "/:userId/plan/:id/info",
		name: "usernameAndInfouser",
		component: Create
	},
	{
		path: "/:userId/plan/:id/what-and-why",
		name: "userwhatAndWhy",
		component: WhatAndWhy
	},
	{
		path: "/:userId/plan/:id/step-1",
		name: "userstep1",
		component: StepOne
	},
	{
		path: "/:userId/plan/:id/step-2",
		name: "userstep2",
		component: StepTwo
	},
	{
		path: "/:userId/plan/:id/step-3",
		name: "userstep3",
		component: StepThree
	},
	{
		path: "/:userId/plan/:id/step-4",
		name: "userstep4",
		component: StepFour
	},
	{
		path: "/:userId/plan/:id/step-5",
		name: "userstep5",
		component: StepFive
	},
	{
		path: "/:userId/plan/:id/last-step",
		name: "userlastStep",
		component: LastStep
	},
	{
		path: "/logout",
		name: "logout",
		// component: Logout
		
		component: () => {
			
			let { userId } = sessionStorage.getAuth();
			document.location.href = process.env.VUE_APP_API_URL+"/user/"+userId+"/logout/";
		}
	},
	{ path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
	{ path: '/:catchAll(.*)', name: 'NotFound', component: NotFound },
];

const router = createRouter({
	history: createWebHashHistory(),
	// history: createWebHistory(),
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return {el: to.hash}
		} else {
			  window.scrollTo(0, 0)
		}
		return {y: 0 }
	}

});
router.beforeEach((to, from) => {
	var nav = document.getElementById('navbar');
	nav.classList.remove("show");
	var modal = document.querySelector('.modal-backdrop')
	if (modal) {
		modal.style.display = "none"
		modal.remove()
	}

})
export default router;
