<template>
	<Layout>
		<div class="page no-page-nav">
			<div class="row">
				<div class="col-lg-6">
					<div class="page-title">
						<h1>{{ state.name }}</h1>
						<p>
							Complete your On-Orchard Biosecurity Plan by
							completing all of the sections below.
						</p>
						<p>
							Submit your plan once all of the sections show a
							green tick
							<span class="tick"><i class="fa fa-tick"></i></span>
						</p>
						<p>
							Or save your plan, and finish it later by finding it
							under
							<router-link to="/plans">My plans.</router-link>
						</p>
					</div>
				</div>
				<Buttons
					:datas="datas"
					:id="plan.data.planId"
					:status="isComplete"
					next="what-and-why"
					name="nameAndInfo"
				></Buttons>
				<div class="col-xl-10 col-xxl-8">
					<div class="row gx-5">
						<Steps :steps="states.steps"></Steps>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	// miport getContent from "../../store/static";
	import { reactive, computed, onMounted } from "vue";
	import { useRoute } from "vue-router";
	import Buttons from "../../components/OverviewButtons.vue";
	import Layout from "./planContainer.vue";
	import Steps from "../../components/Cards.vue";
	import PageNav from "../../components/PageNav.vue";

	export default {
		name: "",
		setup() {
			const store = useStore();
			const route = useRoute();
			// const steps = getContent();
			const states = computed(() => store.state.create);

			const data = computed(() => store.state);
			const datas = computed(() => store.state.create.nameAndInfo);
			const plan = computed(() => store.state.create.plan);

			let isComplete = computed(
				() => store.getters["create/nameAndInfo/getComplete"]
			);

			const state = reactive({
				name: "PROTECTING YOUR ORCHARD FROM ALL BIOSECURITY RISKS",
				status: false,
			});
			const steps = computed(() => store.getters["create/getSteps"]);

			onMounted(() => {
				store.dispatch("create/getsPlan", route.params.id);
				isComplete = computed(
					() => store.getters["create/nameAndInfo/getComplete"]
				);
			});
			return {
				state,
				status,
				data,
				datas,
				states,
				isComplete,
				plan,
				steps,
			};
		},
		components: {
			Steps,
			PageNav,
			Layout,
			Buttons,
		},
	};
</script>

<style lang="scss"></style>
