<template lang="">
  <div class="row plans-list">
    <div class="error">
      <!-- <h3>Cannot fetch
  Plans</h3> -->
      <h3>{{ message }}, Cant fetch plans... <i class="fas fa-redo-alt" @click="getPlans"></i></h3>
    </div>
  </div>
</template>
<script>
	import { useStore } from "vuex";
	export default {
		setup() {
			const store = useStore();
			const getPlans = () => store.dispatch("plans/getPlans");

			return {
				getPlans,
			};
		},
		props: ["message"],
	};
</script>
<style lang="scss">
	@import "../assets/scss/mixins.scss";
	.plans-list {
		.error {
			height: 100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			i.fas {
				margin-left: 10px;
				padding: 5px;
				border: 1px solid grey;
				border-radius: 500px;
				color: grey;
				font-size: 13px;
				cursor: pointer;
			}
		}
	}
</style>
