<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div class="page-title col-lg-8">
					<h1 id="introduction">{{ state.name }}</h1>

					<p>
						Read through the readings below, then proceed down to
						the bottom of the page and answer the questions to
						complete this section.
					</p>
				</div>
				<PageNav>
					<ul>
						<li>
							<router-link to="#introduction"
								>Introduction</router-link
							>
						</li>

						<li>
							<router-link to="#reading"> Reading</router-link>
							<ul>
								<li
									v-for="(item, index) in reading"
									:key="index"
								>
									<router-link :to="'#reading' + index"
										>{{ item.head }}
									</router-link>
								</li>
							</ul>
						</li>

						<li>
							<router-link to="#questions">Questions</router-link>
							<ul>
								<li>
									<span
										v-if="
											data.catch.applicable == true &&
											data.catch.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#catch"
										>Catch It</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.report.applicable == true &&
											data.report.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#report"
										>Report it</router-link
									>
								</li>
								<li>
									<span
										v-if="
											data.record.applicable == true &&
											data.record.content == ''
										"
										class="tick"
										><i class="fa fa-check"></i
									></span>
									<span v-else class="tick accepted">
										<i class="fa fa-check"></i>
									</span>
									<router-link to="#record"
										>Record it</router-link
									>
								</li>
							</ul>
						</li>
					</ul>
				</PageNav>
				<div class="col-lg-8">
					<form class="form" id="step1">
						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="last-step"
							name="step5"
							class="large"
						></Buttons>
						<hr />
						<h2 id="reading">Reading</h2>
						<div class="accordion">
							<div
								v-for="(item, index) in reading"
								class="accordion-item"
								:key="index"
								:id="'heading' + index"
							>
								<h2
									class="accordion-header"
									:id="'reading' + index"
								>
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										:data-bs-target="'#question' + index"
										aria-controls="{{index}}"
									>
										{{ item.head }}
									</button>
								</h2>
								<div
									:id="'question' + index"
									class="accordion-collapse collapse"
									aria-labelledby="{{item.head}}"
								>
									<div class="accordion-body">
										<div v-html="item.body"></div>
									</div>
								</div>
							</div>
						</div>
						<hr />
						<h2 id="questions">Questions</h2>
						<p>
							Below are three questions about being ready to make
							a report after you spot something unusual. Read the
							sample actions and considerations which may help
							guide you on how you would manage this process.
							Enter your plan in the text boxes below.
						</p>
						<div class="block"></div>

						<Question
							boxTitle="catch it, report it and record it"
							subTitle="catch it"
							idTitle="catch"
							radioAcceptance="How I look for biosecurity threats on my orchard:"
							:datas="data.catch"
						>
							<p>Routine monitoring, targeting high-risk:</p>
							<ul>
								<li>
									- areas, such as new plantings and
									vulnerable vines
								</li>
								<li>
									- periods, where risk of infection is
									greater
								</li>
								<li>
									- pest and diseases (know what to look for
									and ensure your staff do too)
								</li>
							</ul>
						</Question>

						<Question
							boxTitle="catch it, report it and record it"
							subTitle="report it"
							idTitle="report"
							radioAcceptance="Reports made in the past 12 months:"
							:datas="data.report"
						>
							<p>
								Report unusual pests you’ve caught or vine
								symptoms to KVH (0800 665 825) or the
								Biosecurity New Zealand hotline (0800 80 99 66)
								within 48 hours
							</p>
							<p>
								Bug photos can also be reported online using the
								free Find-A-Pest app
							</p>
							<p>
								Unusual vine symptoms include Psa-like symptoms
								on a previously ‘Not Detected’ orchard
							</p>
							<p>
								Unmanaged and abandoned orchards must be
								reported to KVH
							</p>
							<p>
								Wild kiwifruit must be reported to your regional
								council (and copied to KVH)
							</p>
						</Question>

						<Question
							boxTitle="catch it, report it and record it"
							subTitle="record it"
							idTitle="record"
							radioAcceptance="Where can my records be found for Monitoring, New plants and budwood, All plant material movements across boundary?"
							:datas="data.record"
							><p>Keep a record of:</p>
							<ul>
								<li>
									all monitoring activities including unusual
									pests and vine health issues (an orchard map
									is an easy way to record locations)
								</li>
								<li>
									new plants and budwood (source and location)
								</li>
								<li>
									all plant movements on and off the property
									(rootstock, budwood, flowers, pollen etc.)
									to retain traceability
								</li>
							</ul>
						</Question>

						<br />
						<br />

						<Buttons
							:datas="datas"
							:id="plan.data.planId"
							:status="isComplete"
							next="last-step"
							name="step5"
						></Buttons>

						<hr />
					</form>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { useStore } from "vuex";
	import { reactive, computed, onMounted } from "vue";
	import Layout from "./planContainer.vue";

	import Buttons from "../../components/Buttons.vue";
	import PageNav from "../../components/PageNav.vue";
	import Question from "../../components/Question.vue";

	export default {
		setup() {
			const store = useStore();

			// const status = computed(() => store.state.create.step1.status);

			const data = computed(() => store.state.create.step5.data);
			const datas = computed(() => store.state.create.step5);
			const reading = computed(() => store.state.create.step5.reading);
			const status = computed(() => store.state.create.step5.status);
			const plan = computed(() => store.state.create.plan);

			const isComplete = computed(
				() => store.getters["create/step5/isComplete"]
			);

			const state = reactive({
				name: "STEP 5: REPORT THE UNUSUAL",
			});

			onMounted(() => {});
			return {
				state,
				status,
				reading,
				datas,
				isComplete,
				data,
				plan,
			};
		},
		components: {
			Buttons,
			PageNav,
			Question,
			Layout,
		},
	};
</script>

<style lang="scss">
	.form-div {
		margin-bottom: 3rem;
		ul > li {
			list-style: none;
			padding-left: 1rem;
			margin-bottom: 0.75rem;
		}
	}
</style>
