<template>
	<Layout>
		<div class="page">
			<div class="row">
				<div
					class="col-lg-3 page-nav"
					data-tor="scroll:class.add(active, {start: 200px})"
				>
					<div>
						<h3>On this page</h3>
						<ul>
							<li data-tor="hover(p):push.up(sm)">
								<router-link to="#what-happens"
									>What happens after I make a
									report?</router-link
								>
							</li>
							<li>
								<router-link to="#after-reporting"
									>After Reporting</router-link
								>
								<ul>
									<li>
										<router-link to="#identification"
											>Identification, assessment and
											response</router-link
										>
									</li>
									<li>
										<router-link to="#effect"
											>Effect on OGR</router-link
										>
									</li>
									<li>
										<router-link to="#talk-to"
											>Who to talk to</router-link
										>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-8">
					<div class="page-title">
						<div id="what-happens">
							<h1>{{ name }}</h1>
							<h2>
								Taking action and reporting an unusual pest or
								vine symptom is a good thing.
							</h2>
							<p>
								The potential benefit of this action to our
								industry cannot be overestimated, but what
								happens next and how will your operation be
								affected? There can be implications for
								biosecurity champions that do the right thing
								and make reports, but it’s worth it because the
								earlier something is reported the greater chance
								we have of eradicating it.
							</p>
						</div>
						<div id="after-reporting">
							<h3>After reporting</h3>
							<p>
								There is a process for what happens next (it’s
								generic and each response may differ slightly)
								and how you can get more information.
							</p>
						</div>
						<div id="identification">
							<br />
							<br />
							<h2>Identification, assessment and response</h2>
							<p>
								The suspicious find is identified. In most
								instances it is found to not be of concern and
								no further action is required.
							</p>
							<p>
								The suspicious find is identified. In most
								instances it is found to not be of concern and
								no further action is required.
							</p>
							<p>
								Biosecurity New Zealand, KVH and any other
								affected groups then work together to decide
								whether to formally respond and if so, set goals
								such as eradication or containment. Sometimes a
								response then moves into long-term management
								(as is the case with Psa currently).
							</p>
						</div>
						<div class="card">
							<div class="card-body">
								<br />
								<h2>
									Actions I can take to ensure smooth-running
								</h2>
								<p>
									Timeframes from notification to a response
									decision vary. For pests with serious
									impacts that we know a lot about (like fruit
									fly) it can be immediate, but for others it
									may take weeks.
								</p>
								<h3>After reporting I:</h3>
								<p>
									Access and provide records and information
									when requested (traceability information of
									plant material movements on and off the
									property is critical for a successful
									response)
								</p>
								<p>
									Follow directions to manage the
									pest/pathogen
								</p>
								<p>
									Respect confidentiality to avoid unnecessary
									market reaction.
								</p>
							</div>
						</div>
						<div id="effect">
							<br />
							<br />
							<h2>Effect on OGR</h2>
							<p>
								Most reports of unusual symptoms turn out to not
								be a biosecurity threat and there are no
								implications for growers. However, if a response
								is activated and losses are incurred because of
								response activities, you will be eligible for
								compensation under the Biosecurity Act (there
								are conditions).
							</p>
							<div class="card">
								<div class="card-body">
									<h2>
										Actions I can take to ensure
										smooth-running
									</h2>
									<p>
										Losses must be verifiable, so good
										production and business records are
										essential for compensation claims.
									</p>
									<p>I know where my records can be found.</p>
								</div>
							</div>
						</div>
						<div id="talk-to">
							<br />
							<br />
							<h2>Who to talk to</h2>
							<p>
								KVH provides regular information advice about
								managing identified pests/pathogens and how a
								response is unfolding.
							</p>
							<p>
								NZKGI provides advice and support information to
								growers.
							</p>
							<p>
								Post-harvest operators help with operations and
								advice.
							</p>

							<div class="card">
								<div class="card-body">
									<h3>
										Actions I can take to ensure
										smooth-running
									</h3>
									<p>
										I have stored phone numbers/contact
										details for KVH, NZKGI and my
										post-harvest operator.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "./mainContainer.vue";
	export default {
		name: "",
		data() {
			return {
				name: "WHAT HAPPENS AFTER I MAKE A REPORT?",
			};
		},
		components: {
			Layout,
		},
	};
</script>

<style lang="scss"></style>
