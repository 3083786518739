<template lang="hmtl">
		
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-3 col-lg-3" ref="target">
					<div class="side-nav">
						<div class="card">
							<Navigation datas="plan"> </Navigation>
						</div>
					</div>
					<router-link to="/dashboard" class="back-home"
						>{{ "<" }} Back home</router-link
					>
				</div>

				<div class="col-sm-12 col-md-9 col-lg-9">
					<div class="page-view" ref="pageView">
						<slot></slot>						
					</div>
				</div>
			</div>
		</div>
</template>
<script>
	import Navigation from "../../components/Nav.vue";
	export default {
		components: {
			Navigation,
		},
	};
</script>
<style lang="scss"></style>
