<template>
	<div>
		<header class="page-header">
			<nav
				id="navbar"
				class="navbar navbar-expand-lg navbar-light bg-light"
				:class="{ show: isActive }"
			>
				<div class="container-fluid">
					<router-link class="navbar-brand" to="/dashboard"
						><img src="../assets/images/logo.svg" alt=""
					/></router-link>

					<router-link to="/logout" class="back-to">
						<i class="fas fa-arrow-left"></i> Return to KVH website
					</router-link>
					<div class="nav-toggle" @click="isActive = !isActive">
						<div class="button">
							<span></span>

							<span></span>

							<span></span>
						</div>
					</div>
				</div>

				<div class="custom-navbar" id="navbarSupportedContent">
					<Navigation :isActive="isActive">
						<li class="nav-item">
							<a class="nav-link" href="#">
								<i class="fas fa-arrow-left"></i>
								Return to KVH website
							</a>
						</li>
					</Navigation>
					<footer>
						<div class="container">
							<img src="../assets/images/logo.svg" alt="" />

							<p>
								Made By
								<a href="http://moca.co.nz" target="_blank">
									MOCA</a
								>
							</p>
						</div>
					</footer>
				</div>
			</nav>
		</header>

		<div class="background">
			<img src="../assets/images/background.svg" alt="" />
		</div>
		<div class="router-view">
			<router-view />
		</div>
		<div class="background mobile">
			<img src="../assets/images/background.svg" alt="" />
		</div>
		<footer>
			<div class="container">
				<img src="../assets/images/logo.svg" alt="" />

				<p>
					Made By
					<a href="http://moca.co.nz" target="_blank"> MOCA</a>
				</p>
			</div>
		</footer>
	</div>
</template>

<script>
	import { reactive, onMounted, ref } from "vue";
	import { afterRouteEnter } from "vue-router";
	import { useStore, mapActions } from "vuex";
	import Navigation from "../components/Nav.vue";

	export default {
		setup() {
			const store = useStore();
		},
		data() {
			return {
				isActive: false,
				expandNav: false,
			};
		},
		watch: {
			$route(to, from) {},
		},
		components: {
			Navigation,
		},
	};
</script>

<style lang="scss">
	@import "../assets/scss/layout";
</style>
