<template lang="">
	<div>		
		<a
			href=""
			class="btn"
			data-bs-toggle="modal"
			data-bs-target="#exampleModal"
			>Start My Plan</a
		>
		<div
			class="modal fade"
			id="exampleModal"
			tabindex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5
							class="modal-title"
							id="exampleModalLabel"
						>
							Start your On-Orchard Biosecurity
							Plan
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn yellow"
							data-bs-dismiss="modal"
						>
							Close
						</button>
						<router-link to="/plan" class="btn">
							Start
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {};
</script>
<style lang="scss">
</style>