<template>
	<Layout>
		<div class="page no-page-nav">
			<div class="row">
				<div class="col-lg-8">
					<div class="page-title">
						{{ userId }}
						<hr />
						{{ test }}
						<hr />
						{{ decoded }}
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import { ref, computed, onMounted } from "vue";
	import { useRoute, useRouter } from "vue-router";
	import { useStore } from "vuex";
	import Layout from "./mainContainer.vue";
	import NewPlan from "../components/NewPlanBtn.vue";

	export default {
		setup() {
			const title = ref("YOUR ON-ORCHARD BIOSECURITY PLAN");

			const route = useRoute();
			const router = useRouter();
			const store = useStore();
			let userId = route.params.userId;
			let authToken = route.params.auth_token;
			let decoded = decodeURI(authToken);
			let test = computed(() => process.env.VUE_APP_LOGIN_URL);

			onMounted(() => {
				store
					.dispatch("getUser", { userId: userId, authToken: decoded })
					.then(() => {
						router.push("/dashboard");
					});
			});
			return {
				title,
				userId,
				decoded,
				test,
			};
		},

		components: {
			Layout,
			NewPlan,
		},
	};
</script>

<style lang="scss">
</style>
