<template lang="">
  <div
    class="modal fade"
    :id="'plan-' + datas.planId"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body row">
          <div class="col-xl-12">
			  
            <div class="row">
              <div class="col-sm-3">
                <p class="d-none d-lg-block">Name</p>
                <h2>{{ datas.fullName }}</h2>
              </div>
              <div class="col-md-3 d-none d-md-block">
                <p>Login</p>
                {{ datas.user ? datas.user.email : "" }}
              </div>
              <div class="col-md-3 mb-hide">
                <p class="d-none d-lg-block">Entry/PMO</p>
                {{ datas.pmoNumber }}
              </div>
              <div class="col-md-3 mb-hide">
                <p class="d-none d-lg-block">Date Submitted</p>
                {{ $filters.formatedDate(datas.submittedAt) }}
              </div>
            </div>
          </div>
          <div calss="col-xs-12">
            <p>
              {{ datas.reminder }}
            </p>
            <br />
          </div>
          <div class="col-xs-12 row">
            <h3 class="col">Download PDF's for each KPIN</h3>
            <div class="col bulk-downloads">
              <a @click="getKpinLinkMaster(datas.planId)">Download Master PDFs</a>
              <!-- <a :href="apiurl+'/plan/'+datas.planId+'/downloadAll/'" target="_blank">Download all PDFs</a> -->
			  <a @click="getKpinLinkZip(datas.planId)" >Download zip</a>
            </div>
          </div>
          <div class="col-xs-12 row">
            <div class="col">
              <div class="pill" v-for="(item, index) in datas.kPins">
                <span>{{ item }}</span>
                <a class="green" @click="getKpinLink(item)" target="_blank">
                  PDF <i class="fas fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import { useStore } from "vuex";
	import { computed } from "vue";
	export default {
		setup(props) {
			const store = useStore();
			// const getKpinLink = (id) => {
			// 	store.dispatch("plans/getKpinLink", { id: id, data: props.datas });
			// };

			const apiurl = computed(() => {
				return process.env.VUE_APP_API_URL;
			});
			const getKpinLinkMaster = (id) => {
				store.dispatch("plans/getKpinLinkMaster", {
					id: id,
					data: props.datas,
				});
			};
			const getKpinLinkZip = (id) => {
				store.dispatch("plans/getKpinLinkZip", {
					id: id,
					data: props.datas,
				});
			};
			const getKpinLink = (kPin) => {
				store.dispatch("plans/getKpinLink", {
					kPin: kPin,
					data: props.datas,
				});
			};
			return {
				apiurl,
				getKpinLink,
				getKpinLinkZip,
				getKpinLinkMaster,
			};
		},
		props: ["datas"],
	};
</script>
<style lang="scss">
	@import "../assets/scss/mixins.scss";
</style>
